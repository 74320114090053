import { rmdCollections } from '../../icons/rmdCollections';
import './RmdIcon.css';

type CollectionType = {
    [key: string]: JSX.Element;
};

type Props = {
    type: string;
    collection?: keyof typeof rmdCollections
    customCollection?: CollectionType;
};

export const RmdIcon = ({ type, collection = 'rmd', customCollection}: Props) => {
    const currentCollection: CollectionType = customCollection
        ? customCollection
        : rmdCollections[collection];

    return (
        <span className="rmd-icon">
            { currentCollection[type] }
        </span>
    );
};