import "./ControlPanel.css";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PlayerControls, PlayerControlStore } from "features/PlayerControls";
import { SongFavoriteModel } from "entities/Song";
import { formatTime } from "shared/lib/date";

import { TrackDetails } from "../TrackDetails/TrackDetails";

type Props = {
  playerControlStore: PlayerControlStore;
  stationTitle: string;
  time: any;
  isCustomStation: boolean;
  customStationData: SongFavoriteModel[];
};

/**
 * Компонент для переключения треков (Prev, Play, Next)
 * и управление громкостью
 */

export const ControlPanel = observer(({
  playerControlStore,
  stationTitle,
  time,
  isCustomStation,
  customStationData
}: Props) => {
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    if (!time || isCustomStation) {
      return;
    }
    let intervalId: NodeJS.Timer;

    setRemainingTime(time.remaining);
    intervalId = setInterval(() => {
      setRemainingTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="player-control-panel">
      <div className="player-control-panel__wrapper">
        <PlayerControls
          audioRef={playerControlStore.audioRef}
          svgRef={playerControlStore.svgRef}
          playerControlStore={playerControlStore}
          customStationData={customStationData}
        />
        <TrackDetails
          title={isCustomStation
            ? `${stationTitle} / NEXT TRACK: ${formatTime(remainingTime)}`
            : stationTitle
          }
        />
      </div>
    </div>
  );
});