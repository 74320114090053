import { observer } from 'mobx-react'
import { RmdLogo } from '../shared/ui/RmdLogo/RmdLogo';
import './RmdPage.css';

type PageElement = JSX.Element | null | string | boolean | number;

type Props = {
    headerLeft?: PageElement;
    headerMiddle?: PageElement;
    headerRight?: PageElement;
    body?: PageElement;
    children?: PageElement;
    footer?: PageElement;
};

export const RmdPage = observer(({
    headerLeft, headerMiddle, headerRight, body, children, footer
}: Props) => {
    return (
        <div className="rmd-page">
            <div className="rmd-page__header">
                <div className="rmd-page__header-left">
                    {headerLeft}
                </div>
                <div className="rmd-page__header-middle">
                    {!headerMiddle ? <RmdLogo /> : headerMiddle  }
                </div>
                <div className="rmd-page__header-right">
                    {headerRight}
                </div>
            </div>
            <div className="rmd-page__body">
                {body}
            </div>
            {children}
            { footer &&
                <footer className="rmd-page__footer">
                    { footer  }
                </footer>
            }
        </div>
    )
});