import './App.css';
import 'tippy.js/dist/tippy.css';

import { observer } from "mobx-react-lite";
import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from './AppRouter';

export const App = observer(() => {
    return (
        <div className="app">
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </div>
    );
});