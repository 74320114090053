import { observer } from "mobx-react-lite";
import cn from 'classnames';
import { NotificationModel } from '../../entities/Notification';
import { IconButton } from "../../shared/ui/IconButton";
import { RmdIcon } from "../../shared/ui/RmdIcon";
import './Notification.css';
import {useState} from "react";

type Props = {
    entity: NotificationModel;
    onRemove: (entity: NotificationModel) => void;
};

export const Notification = observer(({ entity, onRemove }: Props) => {
    const [isRemoved, setIsRemoved] = useState(false);

    return (
        <li className={cn(
            "notification",
            {"notification_removed": isRemoved}
        )}>
            <h3 className={cn(
                "notification__title",
                `notification__title_${entity?.status}`
            )}>
                { entity?.title }
            </h3>
            <IconButton
                className="notification__remove-btn"
                onClick={() => {
                    setIsRemoved(true);
                    setTimeout(() => onRemove(entity), 500);
                }}
            >
                <RmdIcon type="closeIconSmall" />
            </IconButton>
            {entity?.body &&
                <p className="notification__text">{ entity.body }</p>
            }
        </li>
    );
});