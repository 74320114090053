import { CustomStationModel, StationModel } from "entities/Station";
import { SongFavoriteModel, SongModel } from "entities/Song";
import { NowPlayingModel } from "entities/NowPlaying";

export async function runPlayAfterSwitch(
  entity: StationModel | CustomStationModel,
  currentStation: StationModel | CustomStationModel | null,
  isPlaying: boolean,
  togglePlay: (state: boolean, path: string) => void,
  setPlayingData: (data: NowPlayingModel) => void,
  track: SongFavoriteModel,
) {
  const songModel = new SongModel(track);
  const songUrl = await songModel.onDownloadSong({
    songPath: track.path,
    storageId: track.storageLocationId
  });
  const model = new NowPlayingModel({
    duration: parseInt(track.length),
    elapsed: 0,
    remaining: 0,
    song: track
  });
  setPlayingData(model);

  if (entity.id === currentStation?.id) {
    if (entity.name === "personal" && songUrl) {
      togglePlay(!isPlaying, songUrl.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
    } else {
      togglePlay(!isPlaying, entity.audioUrl);
    }
  } else {
    if (entity.name === "personal" && songUrl) {
      togglePlay(true, songUrl?.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
    } else {
      togglePlay(true, entity.audioUrl);
    }
  }
}