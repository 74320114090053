import './TabItem.css';

import Tippy from '@tippyjs/react';
import cn from 'classnames';

import { ITabOption } from './types/ITabOption';
import { useLocation } from 'react-router-dom';

type Props = {
  name: ITabOption["name"];
  action: ITabOption["action"];
  isActive: ITabOption["isActive"];
  path: ITabOption["path"];
};

export const TabItem = ({ name, action, isActive, path }: Props) => {
  const { pathname } = useLocation();

  const elementCN = cn(
    "tab-item",
    { "tab-item_current": pathname === path && isActive },
    { "tab-item_inactive": !isActive }
  );

  return isActive
    ? (
      <li
        onClick={action}
        className={elementCN}
      >
        {name}
      </li>
    )
    : (
      <Tippy content={!isActive && "Не доступно"}>
        <li
        onClick={action}
        className={elementCN}
      >
        {name}
      </li>
     </Tippy>
    )
};