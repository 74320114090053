import { observer } from "mobx-react-lite";
import { CircleButton } from "../../shared/ui/CircleButton";
import { rmdIcons } from "../../shared/icons";
import './AuthLogOut.css';
import {useTranslation} from "react-i18next";

type Props = {
    action: () => void;
};

export const AuthLogOut = observer(({ action }: Props) => {
    const { t } = useTranslation();

    return (
        <CircleButton
            action={action}
            children={rmdIcons.logout}
            hint={t("logout")}
        />
    );
});