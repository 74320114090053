import { observer } from "mobx-react-lite";
import uuid from "react-uuid";
import { NotificationStore } from "../../store/NotificationStore";
import { NotificationModel } from "../../../../entities/Notification";
import { Notification } from "../../Notification";
import "./NotificationList.css";

type Props = {
  store: NotificationStore;
};

export const NotificationList = observer(({ store }: Props) => {
    const handleItemDelete = (model: NotificationModel) => {
        if (!store) return;
        store.removeFromStack(model);
    };

    return (
       <ul className="notification-list">
           {store.stack.map((item) => <Notification
                key={uuid()}
                entity={item}
                onRemove={handleItemDelete}
           />)}
       </ul>
    )
});