import "./Plans.css";

import { ITariffModel } from "entities/Tariff";
import { makeFromObjectMap } from "shared/lib";

import { Plan } from "./Plan";

export const Plans = () => {
  const tariffsConfigMap: Record<PropertyKey, ITariffModel> = {
    "free": {
      price: 0,
      name: "Freemium",
      info: [
        "20 лайков",
        "1 персональная станция",
        "Без рекламы"
      ],
    },
    "medium": {
      price: 1,
      name: "Listener Pro",
      info: [
        "Безлимитные лайки / скачивания",
        "3+ уникальных станций с новым контентом",
        "Что-то еще",
      ],
      action: () => 1,
    },
    "pro": {
      price: 1000,
      name: "DJ Team",
      info: [
        "Cоздавай бесконечное количество персональных станций",
        "Интерфейс для собственной команды (лейбла)",
        "Возможность стримить на главной сайта",
        "Прочее сотрудничество",
      ],
      action: () => 1,
    }
  };
  const tariffs = makeFromObjectMap(tariffsConfigMap);

  return (
    <ul className="plans">{tariffs.map(({ key, item }) =>
      <Plan key={item.name} title={key} config={item} />
    )}</ul>
  );
};