import cn from 'classnames';
import './CircleButton.css';
import { TippyWrapper } from "../../lib/tippy";
import { ReactNode } from "react";

type Props = {
    action?: () => void;
    className?: string;
    children: JSX.Element;
    hint?: ReactNode;
    interactive?: boolean;
    isDisabled?: boolean;
};

export const CircleButton = ({
    action,
    className,
    children,
    hint,
    interactive,
    isDisabled
}: Props) => {
    const btnClassName = cn(
        "circle-button",
        "custom-reset-btn",
        className && className
    );

    return (
        <TippyWrapper
          content={hint}
          interactive={interactive}
        >
            <button
                className={btnClassName}
                onClick={action}
                type="button"
                aria-label="styled button"
                disabled={isDisabled}
            >
                { children }
            </button>
        </TippyWrapper>
    );
};