import "./VolumeBar.css";

import { observer } from "mobx-react-lite";

import { VolumeBarStore } from "../../model/VolumeBarStore";
import { VolumeSlider } from "./VolumeSlider";
import { VolumeBtn } from "./VolumeBtn";

type Props = {
  store: VolumeBarStore;
  isVertical?: boolean;
};

export const VolumeBar = observer(({
  store,
  isVertical = false
}: Props) => {
  const { volume } = store;

  return (
    <div className="player-volume-bar">
      {!isVertical && <VolumeBtn
        mute={store.mute}
        setMute={() => store.toggleMute()}
      />}
      <VolumeSlider
        volume={volume}
        setVolume={store.setVolume}
        isVertical={isVertical}
      />
    </div>
  );
});
