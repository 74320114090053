import { useEffect, useRef } from "react";

type ClickHandler = (event: MouseEvent) => void;

export function useClickOutside(onClick: ClickHandler) {
    const ref = useRef(null);

    useEffect(() => {
        function handleOutsideClick(event: MouseEvent) {
            //@ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                onClick(event);
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [ref, onClick]);

    return ref;
}