import { EntityMediaModel, IEntityMediaModelConfig } from "shared/model";

import { SongModel } from "../Song";

export interface IMediaModelConfig extends IEntityMediaModelConfig{
    song: SongModel | null;
}

export abstract class MediaModel extends EntityMediaModel {
    song: SongModel | null = null;

    protected constructor(config: IMediaModelConfig) {
        super(config);
        this.song = config.song && new SongModel(config.song);
    }
}