import cn from 'classnames';
import './IconButton.css';

type Props = {
    onClick: () => void;
    children: JSX.Element;
    className?: string;
    isDisabled?: boolean;
};

export const IconButton = ({ onClick, children, className, isDisabled }: Props) => {
    return (
        <button
            className={cn(
                "icon-button",
                className,
                "custom-reset-btn",
            )}
            onClick={onClick}
            type="button"
            aria-label="icon-button"
            disabled={isDisabled}
        >
            { children }
        </button>
    );
};