export default {
  en: {
    translation: {
      registration: "Registration",
      login: "Login",
      authorization: "Authorization",
    },
  },
  ru: {
    translation: {
      registration: "Регистрация",
      login: "Войти",
      authorization: "Авторизация",
      password: "Пароль",
      forgotPassword: "Забыли пароль",
      restorePassword: "Восставновить пароль",
      name: "Имя",
      surname: "Фамилия",
      repeatPassword: "Повторите пароль",
      registrationSuccess: "Запрос на регистрацию отправлен",
      emailAlreadyExists: "Указанный email уже существует!",
      emailUnverified: "Почта не верифицирована!",
      emailNotSpecified: "почта не указана",
      letterWasSent: "Письмо было отправлено на почту",
      verifiedSuccessfully: "Успешная авторизация",
      verificationCompleted: "Вы успешно верифицровали почту",
      verificationError: "Ошибка при верификации почты",
      logoutSuccess: "Вы вышли из системы",
      logoutFailure: "Не удалось корректно разлогиниться",
      loginFailure: "Не получилось авторизоваться",
      wrongEmailOrPass: "Неправильная почта или пароль",
      recoverRequested: "Запрос отправлен",
      passChangeFailure: "Ошибка при попытке изменения пароля",
      passChangeFailureError: "При попытке восстановления пароля произошла ошибка",
      sidebarMenu: "Боковое меню",
      authRequired: "Необходима авторизация",
      noData: "Данные отсутствуют",
      pause: "Пауза",
      play: "Играть",
      next: "Вперед",
      previous: "Назад",
      unavailable: "Не доступно",
      verification: "Верификация",
      errorGettingTracks: "Ошибка при получании треков кастомной станции",
      likeError: "Ошибка при постановке лайка треку",
      myPlaylist: "Мой плейлист",
      profile: "Профиль",
      tariffs: "Тарифы",
      support: "Поддержка",
      pageNotFound: "Страница не найдена",
      downloadError: "Ошибка при скачивании трека",
      passRestore: "Восстановление пароля",
      restore: "Восстановить",
      historyRequestError: "Ошибка при запросе истории треков",
      stationsRequestError: "Ошибка получения списка станций",
      topTracksRequestError: "Ошибка получения списка топа треков",
      myTracksRequestError: "Ошибка получения списка треков my-station",
      logout: "Выйти",
      last5Tracks: "Последние 5 треков",
      myTracks: "Мои треки",
      top: "Топ",
      createNewPass: "Создание нового пароля",
      submit: "Подтвердить",
      passRemember: "Я вспомнил пароль",
      trackInfo: "Инфо о треке",
    },
  },
}