import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RmdIcon } from "shared/ui/RmdIcon";

import { stationIcons } from "../icons/stationIcons";
import { formatStationName } from "../model/formatStationName";

interface Props {
    isActive: boolean;
    stationName: string;
    stationIcon: string;
    className: string;
    action: () => void;
}

export const StationLayout = observer(({
  isActive,
  stationIcon,
  stationName,
  className,
  action
}: Props) => {
  const location = useLocation();

  return (
    isActive ? (
      <Link
        className="station__link-wrapper"
        to={`/station/${formatStationName(stationName)}`}
        state={{ from: location }}
      >
        <li
          className={className}
          onClick={action}
        >
          <RmdIcon
            customCollection={stationIcons}
            type={stationIcon}
          />
          <p className="station__name">{stationName}</p>
        </li>
      </Link>
      ) : (
      <li className={className}>
        <RmdIcon
          customCollection={stationIcons}
          type={stationIcon}
        />
        <p className="station__name">{stationName}</p>
      </li>
    )
  )
});