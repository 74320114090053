import './Stations.css';

import { useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import { CustomStationModel, StationModel, Station } from 'entities/Station';
import { StationUnionType } from "entities/Station/types";

import { StationSwitchStore } from "./model/StationSwitchStore";

type Props = {
  isAuth: boolean;
  actionAfterSwitch: (
    model: StationUnionType,
    customStation: StationUnionType | null
  ) => void;
};

export const Stations = observer(({
  isAuth,
  actionAfterSwitch
}: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const store = StationSwitchStore.getShared();
  const { stationList, currentStation} = store;

  const handleSetStation = (model: StationModel | CustomStationModel) => {
    if (!model.isAvailable) return;
    store.setCurrent(model);
    actionAfterSwitch(model, currentStation);
  };

  useEffect(() => {
    store.setupStations();
  }, []);

  useEffect(() => {
    if (isAuth) {
      store.getStationList();
    }
  }, [isAuth]);

  return (
    <ul ref={ref} className="stations">
      {stationList.map((item: StationModel) => <Station
        key={item.title}
        model={item}
        isCurrent={currentStation?.id === item.id}
        handleStationClick={handleSetStation}
        isAuth={isAuth}
      />)}
    </ul>
  );
});