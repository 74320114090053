import { action, makeObservable, observable } from "mobx";

export class SidebarStore {
    sidebarOpened: boolean = false;

    constructor() {
        makeObservable(this, {
            sidebarOpened: observable,
            openSidebar: action,
            closeSidebar: action
        })
    }

    openSidebar() {
        this.sidebarOpened = true;
    }

    closeSidebar() {
        this.sidebarOpened = false;
    }
}