import { action, makeObservable, observable } from "mobx";

import { NowPlayingModel } from "../NowPlayingModel";


export class NowPlayingStore {
  nowPlayingData: NowPlayingModel | null = null;

  constructor() {
    makeObservable(this, {
      nowPlayingData: observable,
      setNowPlayingData: action
    });
  }

  setNowPlayingData(data: NowPlayingModel) {
    this.nowPlayingData = data;
  }
}