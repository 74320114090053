import { toggleLike } from "features/Like";

import { downloadSong } from "features/Download";

import { ISongConfig } from "../types/ISongConfig";

export class SongModel {
  public id: string = '';

  text: string = '';

  title: string = '';

  album: string = '';

  art: string = '';

  artist: string = '';

  customFields?: unknown[] = [];

  genre: string = '';

  isrc: string = '';

  lyrics: string = '';

  constructor(backendData: ISongConfig) {
    this.id = backendData.id;
    this.text = backendData.text;
    this.title = backendData.title;
    this.album = backendData.album;
    this.art = backendData.art;
    this.artist = backendData.artist;
    this.customFields = backendData.custom_fields;
    this.genre = backendData.genre;
    this.isrc = backendData.isrc;
    this.lyrics = backendData.lyrics;
  }

  public onDownloadSong({ songPath, storageId }: { songPath: string; storageId?: number; }) {
    return downloadSong({ songPath, storageId });
  }

  public onToggleLike({ songId, stationId }: { songId: string; stationId?: number; }) {
    return toggleLike({ songId, stationId });
  }
}