import './SidebarCloseBtn.css';

import { IconButton } from 'shared/ui/IconButton';
import { RmdIcon } from 'shared/ui/RmdIcon';

type Props = {
    action: () => void;
};

export const SidebarCloseBtn = ({ action }: Props) => {
    return (
        <IconButton
            onClick={action}
            children={
                <RmdIcon type="closeIcon" />
            }
        />
    );
};