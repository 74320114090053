import "./PlayerControls.css";

import { RefObject, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AppStore } from "app";
import { SongFavoriteModel, SongModel } from "entities/Song";
import { NowPlayingModel } from "entities/NowPlaying";
import { TippyWrapper } from "shared/lib/tippy";
import { pagePaths } from "shared/config/pagePaths";

import { ModalActionStore } from "./model/ModalActionStore";
import { ControlBtn } from "./ControlBtn";
import { PlayerControlStore } from "./model/PlayerControlStore";

type Props = {
  audioRef: RefObject<HTMLAudioElement>;
  svgRef: RefObject<HTMLElement>;
  playerControlStore: PlayerControlStore;
  customStationData: SongFavoriteModel[];
};

export const PlayerControls = observer(
  ({ audioRef, playerControlStore, customStationData }: Props) => {
    const { appMediaStore } = AppStore.getShared();
    const { nowPlayingStore, stationSwitchStore } = appMediaStore;
    const modalStore = ModalActionStore.getShared();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
      const keyboardControl = (evt: KeyboardEvent) => {
        if (!modalStore.isActiveModal) {
          if (evt.code === 'Space') return pauseTrack();
          if (evt.code === 'KeyL') return loadNext();
          if (evt.code === 'KeyR') return loadPrev();
          if (evt.code === 'ArrowLeft') return console.log("ArrowLeft");
          if (evt.code === 'ArrowRight') return console.log("ArrowRight");
        }
      };
      document.addEventListener('keydown', keyboardControl)
      return () => {
        document.removeEventListener('keydown', keyboardControl)
      }
    }, [modalStore.isActiveModal])

    const loadPrev = async () => {
      if (stationSwitchStore.currentStation?.name === 'personal') {
        const currentIdPlaying = customStationData.findIndex(el => el.id === nowPlayingStore.nowPlayingData?.song?.id);
        let newSongIndex: number;
        if (currentIdPlaying === 0) {
          newSongIndex = customStationData.length - 1;
        } else {
          newSongIndex = currentIdPlaying - 1;
        }
        await playNextSong(newSongIndex);
      } else {
        stationSwitchStore.switchStation("previous");
        playerControlStore.togglePlay(true, stationSwitchStore.currentStation?.audioUrl);
      }
    };

    const loadNext = async () => {
      if (stationSwitchStore.currentStation?.name === 'personal') {
        const currentIdPlaying = customStationData.findIndex(el => el.id === nowPlayingStore.nowPlayingData?.song?.id);
        let newSongIndex: number;
        if (currentIdPlaying === customStationData.length - 1) {
          newSongIndex = 0;
        } else {
          newSongIndex = currentIdPlaying + 1;
        }
        await playNextSong(newSongIndex);
      } else {
        stationSwitchStore.switchStation("next");
        playerControlStore.togglePlay(true, stationSwitchStore.currentStation?.audioUrl);
      }
    };

    const hintStationName = () => {
      const availableStations = stationSwitchStore.stationList.filter((station) => station.isAvailable);
      const currentIndex = availableStations.findIndex(station => station.id === stationSwitchStore.currentStation?.id);
      const stationsLength = availableStations.length;

      const previous = availableStations[(currentIndex + stationsLength - 1) % stationsLength];
      const next = availableStations[(currentIndex + 1) % stationsLength];
      return {
        previous,
        next
      }
    }

    const prevSongName = () => {
      const currentIdPlaying = customStationData.findIndex(el => el.id === nowPlayingStore.nowPlayingData?.song?.id);
      let newSongIndex: number;
      if (currentIdPlaying === 0) {
        newSongIndex = customStationData.length - 1;
      } else {
        newSongIndex = currentIdPlaying - 1;
      }
      return customStationData[newSongIndex]?.text || "";
    }

    const nextSongName = () => {
      const currentIdPlaying = customStationData.findIndex(el => el.id === nowPlayingStore.nowPlayingData?.song?.id);
      let newSongIndex: number;
      if (currentIdPlaying === customStationData.length - 1) {
        newSongIndex = 0;
      } else {
        newSongIndex = currentIdPlaying + 1;
      }
      return customStationData[newSongIndex]?.text || "";
    }

    const pauseTrack = () => {
      if (location.pathname === "/") {
        navigate(pagePaths.deep);
      }
      if (stationSwitchStore.currentStation?.name === 'personal') {
        playerControlStore.togglePlay(!playerControlStore.isPlaying, playerControlStore.audioRef.current.src)
      } else {
        playerControlStore.togglePlay(!playerControlStore.isPlaying, stationSwitchStore.currentStation?.audioUrl)
      }
    }

    const playNextSong = async (newSongIndex: number) => {
      const songModel = new SongModel(customStationData[newSongIndex])
      const songUrl = await songModel.onDownloadSong({
        songPath: customStationData[newSongIndex].path,
        storageId: customStationData[newSongIndex].storageLocationId
      });
      playerControlStore.togglePlay(true, songUrl?.replace(`https://${process.env.REACT_APP_BACKEND_DOMAIN}`, ""));
      const model = new NowPlayingModel({ duration: parseInt(customStationData[newSongIndex].length), elapsed: 0, remaining: 0, song: customStationData[newSongIndex] })
      nowPlayingStore.setNowPlayingData(model)
    }

    return (
      <div className={`player-controls ${location.pathname === '/' ? 'main-page' : ''}`}>
        {
          location.pathname !== "/" &&
          <TippyWrapper content={`${stationSwitchStore.currentStation?.name === 'personal' ? prevSongName() : hintStationName().previous.title} (${t("previous")} [R])`}>
            <div>
              <ControlBtn
                type="secondary"
                icon="prevButton"
                action={() => loadPrev()}
              />
            </div>
          </TippyWrapper>
        }
        <TippyWrapper content={playerControlStore.isPlaying ? `${t("pause")} [P]` : `${t("play")} [P]`}>
          <div>
            <ControlBtn
              type="primary"
              icon={playerControlStore.isPlaying ? "pauseButton" : "playButton"}
              action={pauseTrack}
            />
          </div>
        </TippyWrapper>
        {
          location.pathname !== "/" &&
          <TippyWrapper content={`${stationSwitchStore.currentStation?.name === 'personal' ? nextSongName() : hintStationName().next.title} (${t("next")} [L])`}>
            <div>
              <ControlBtn
                type="secondary"
                icon="nextButton"
                action={() => loadNext()}
              />
            </div>
          </TippyWrapper>
        }
        <audio id="audio" ref={audioRef}/>
      </div>
    );
  }
);
