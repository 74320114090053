import { Fragment, useRef } from "react";
import { observer } from "mobx-react-lite";

import { AppStore } from "app";
import { SongFavoriteModel } from "entities/Song";

import { VolumeBarStore } from "./model/VolumeBarStore";
import { Player } from "./index";
import { PlayerStore } from "./model/PlayerStore";

type Props = {
  handleNonAuthAction: () => void;
  isEqualizerOn?: boolean;
  stationTitle?: string;
  customStationData?: SongFavoriteModel[];
};

export const Layout = observer(({
  handleNonAuthAction,
  isEqualizerOn = false,
  stationTitle = "",
  customStationData = [],
}: Props) => {
  const { appMediaStore, authProcess } = AppStore.getShared();
  const {
    nowPlayingStore,
    stationSwitchStore,
    favouriteStore,
  } = appMediaStore;
  const { playerControlStore } = PlayerStore.getShared();
  const volumeBarStore = VolumeBarStore.getShared();

  playerControlStore.audioRef = useRef<HTMLAudioElement>(null);
  playerControlStore.svgRef = useRef<HTMLElement>(null);

  const timeLineRef = useRef<HTMLElement>(null);
  const { authStore } = authProcess;
  const { isAuth } = authStore;

  const { currentStation, topTracks, myTracks } = stationSwitchStore;
  const { nowPlayingData } = nowPlayingStore;
  const { likedSongs } = favouriteStore;

  const isCustomStation = currentStation?.isCustom ?? false;

  if (!nowPlayingData) return <Fragment/>;
  const { song } = nowPlayingData;
  if (!song) return <Fragment />;

  const time = {
    elapsed: nowPlayingData.elapsed,
    duration: nowPlayingData.duration,
    remaining: nowPlayingData.remaining
  };

  return (
      <Player.Container
        layoutType={isCustomStation ? "small" : "regular"}
        equalizer={
          <Player.Equalizer
            svgRef={playerControlStore.svgRef}
            isVisible={isEqualizerOn}
          />
        }
        timeline={
          <Player.TimeLine
            time={time}
            timeLineRef={timeLineRef}
            isVisible={isCustomStation}
          />
        }
        trackInfo={
          <Player.TrackInfo song={song} />
        }
        controlPanel={
          <Player.ControlPanel
            playerControlStore={playerControlStore}
            isCustomStation={isCustomStation}
            stationTitle={stationTitle}
            time={time}
            customStationData={customStationData}
          />
        }
        volumeBar={
          <Player.VolumeBar store={volumeBarStore} />
        }
        options={
          <Player.Actions
            model={nowPlayingData}
            likedSongs={likedSongs}
            handleNonAuthAction={handleNonAuthAction}
            isAuth={isAuth}
          />
        }
      />
  );
});
