import { ReactNode } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import "./TippyWrapper.css"

type Props = {
  content?: ReactNode;
  children: JSX.Element;
} & Exclude<TippyProps, "content">;

export function TippyWrapper({ content, children, ...tippyProps }: Props) {
  if (!content) {
    return children;
  }

  return (
    <Tippy {...tippyProps} arrow={false} content={<div className={`${process.env.REACT_APP_TRANSPARENT_HOVER ? "transparent-hover" : ""} tippy-tooltip`}>{ content }</div>}>
      { children }
    </Tippy>
  );
}