import { pagePaths } from "../shared/config/pagePaths";
import { Account } from "./Account/Account";
import { Home } from "./Home";
import { PageNotFound } from "./PageNotFound";
import { Tariffs } from "./Tariffs";
import { Playlist } from "./Playlist";
import { Profile } from "./Profile";
import { Support } from "./Support";
import { PasswordReset } from "./PasswordReset";
import { Verify } from "./Verify";

export const pageRoutes = {
    [pagePaths.HOME_ROUTE]: Home,
    [pagePaths.PAGE_NOT_FOUND_ROUTE]: PageNotFound,
    [pagePaths.PROFILE_ROUTE]: Profile,
    [pagePaths.PLAYLIST_ROUTE]: Playlist,
    [pagePaths.ACCOUNT_ROUTE]: Account,
    [pagePaths.TARIFFS_ROUTE]: Tariffs,
    [pagePaths.SUPPORT_ROUTE]: Support,

    /* Восстановление пароля */
    [pagePaths.PASSWORD_RESET]: PasswordReset,

    /* Верификация аккаунта */
    [pagePaths.VERIFY]: Verify,

    /* Станции */
    [pagePaths.station]: Home,
    [pagePaths.dnb]: Home,
    [pagePaths.deep]: Home,
    [pagePaths.house]: Home,
    [pagePaths.personal]: Home
};