const playButton = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.15 28.5L28.5 20L15.15 11.5V28.5ZM20 40C17.2667 40 14.6833 39.475 12.25 38.425C9.81667 37.375 7.69167 35.9417 5.875 34.125C4.05833 32.3083 2.625 30.1833 1.575 27.75C0.525 25.3167 0 22.7333 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05833 7.65 5.875 5.85C7.69167 4.05 9.81667 2.625 12.25 1.575C14.6833 0.525 17.2667 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7333 39.475 25.3167 38.425 27.75C37.375 30.1833 35.95 32.3083 34.15 34.125C32.35 35.9417 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40ZM20 37C24.7333 37 28.75 35.3417 32.05 32.025C35.35 28.7083 37 24.7 37 20C37 15.2667 35.35 11.25 32.05 7.95C28.75 4.65 24.7333 3 20 3C15.3 3 11.2917 4.65 7.975 7.95C4.65833 11.25 3 15.2667 3 20C3 24.7 4.65833 28.7083 7.975 32.025C11.2917 35.3417 15.3 37 20 37Z" fill="white"/>
</svg>;

const pauseButton = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 28H17.5V12H14.5V28ZM22.5 28H25.5V12H22.5V28ZM20 40C17.2667 40 14.6833 39.475 12.25 38.425C9.81667 37.375 7.69167 35.9417 5.875 34.125C4.05833 32.3083 2.625 30.1833 1.575 27.75C0.525 25.3167 0 22.7333 0 20C0 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76667 4.05833 7.65 5.875 5.85C7.69167 4.05 9.81667 2.625 12.25 1.575C14.6833 0.525 17.2667 0 20 0C22.7667 0 25.3667 0.525 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7333 39.475 25.3167 38.425 27.75C37.375 30.1833 35.95 32.3083 34.15 34.125C32.35 35.9417 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40ZM20 37C24.7333 37 28.75 35.3417 32.05 32.025C35.35 28.7083 37 24.7 37 20C37 15.2667 35.35 11.25 32.05 7.95C28.75 4.65 24.7333 3 20 3C15.3 3 11.2917 4.65 7.975 7.95C4.65833 11.25 3 15.2667 3 20C3 24.7 4.65833 28.7083 7.975 32.025C11.2917 35.3417 15.3 37 20 37Z" fill="white"/>
</svg>;

const prevButton = <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 18.5L15.0481 9.5L28 0.5V18.5ZM12.9519 18.5L0 9.5L12.9519 0.5V18.5ZM10.7059 14.1875V4.8125L3.93048 9.5L10.7059 14.1875ZM25.754 14.1875V4.8125L18.9786 9.5L25.754 14.1875Z" fill="white"/>
</svg>;

const nextButton = <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 18.5V0.5L12.8659 9.5L0 18.5ZM15.1341 18.5V0.5L28 9.5L15.1341 18.5ZM2.23108 14.1875L8.96149 9.5L2.23108 4.8125V14.1875ZM17.3652 14.1875L24.0956 9.5L17.3652 4.8125V14.1875Z" fill="white"/>
</svg>;

const volumeOnIcon = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1111 27.5V25.1086C19.6259 24.3886 21.6806 23.0064 23.275 20.9621C24.8694 18.9179 25.6667 16.5971 25.6667 14C25.6667 11.4029 24.8759 9.07571 23.2944 7.01857C21.713 4.96143 19.6519 3.58571 17.1111 2.89143V0.5C20.3259 1.22 22.9444 2.83357 24.9667 5.34071C26.9889 7.84786 28 10.7343 28 14C28 17.2657 26.9889 20.1521 24.9667 22.6593C22.9444 25.1664 20.3259 26.78 17.1111 27.5ZM0 18.6671V9.41H6.22222L14 1.69571V26.3814L6.22222 18.6671H0ZM16.3333 20.5186V7.52C17.7593 7.95714 18.8935 8.78 19.7361 9.98857C20.5787 11.1971 21 12.5471 21 14.0386C21 15.5043 20.5722 16.8414 19.7167 18.05C18.8611 19.2586 17.7333 20.0814 16.3333 20.5186ZM11.6667 7.55857L7.27222 11.7243H2.33333V16.3529H7.27222L11.6667 20.5571V7.55857Z" fill="white"/>
</svg>;

const volumeOffIcon = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.5081 28L21.9281 23.4201C21.2805 23.8827 20.5808 24.2817 19.829 24.6171C19.0772 24.9525 18.2966 25.2127 17.487 25.3978V23.2466C18.019 23.0847 18.5337 22.9054 19.031 22.7088C19.5283 22.5122 19.9967 22.252 20.4362 21.9281L14.7113 16.1685V24.3916L7.772 17.4523H2.22057V9.12516H7.63321L0 1.49195L1.49195 0L28 26.4734L26.5081 28ZM25.259 19.9504L23.767 18.4585C24.2297 17.672 24.5708 16.8402 24.7906 15.963C25.0103 15.0858 25.1202 14.1828 25.1202 13.254C25.1202 10.8641 24.4263 8.7284 23.0384 6.84695C21.6506 4.96551 19.8001 3.77034 17.487 3.26146V1.11029C20.3552 1.75795 22.6915 3.20942 24.4957 5.46468C26.2999 7.71995 27.202 10.3164 27.202 13.254C27.202 14.4337 27.0401 15.5903 26.7162 16.7237C26.3924 17.8571 25.9067 18.9327 25.259 19.9504ZM20.6097 15.3011L17.487 12.1784V7.66791C18.5741 8.17679 19.4242 8.94011 20.0372 9.95787C20.6501 10.9756 20.9566 12.0859 20.9566 13.2887C20.9566 13.6357 20.9277 13.9769 20.8699 14.3123C20.8121 14.6477 20.7253 14.9773 20.6097 15.3011ZM14.7113 9.40273L11.1028 5.7943L14.7113 2.18587V9.40273ZM12.6295 19.3259V14.1214L9.71499 11.2069H4.30235V15.3705H8.6741L12.6295 19.3259Z" fill="white"/>
</svg>;

export const playerIcons = {
    playButton,
    pauseButton,
    prevButton,
    nextButton,
    volumeOnIcon,
    volumeOffIcon
};