import './Sidebar.css';

import { observer } from "mobx-react-lite";
import cn from 'classnames';

import { useClickOutside } from 'shared/hook/useClickOutside';
import { SongFavoriteModel, Tracks } from "entities/Song";
import { FavouriteModel } from "entities/Favourite";

import { SidebarCloseBtn } from './ui/SidebarCloseBtn/SidebarCloseBtn';
import { useTranslation } from "react-i18next";

type Props = {
  sidebarState: boolean;
  onSidebarClose: () => void;
  tracks: SongFavoriteModel[];
  historyTracks?: SongFavoriteModel[];
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
  isCustomStation: boolean;
};

const TopTracks = Tracks;
const LastTracks = Tracks;

export const Sidebar = observer(({
  sidebarState,
  onSidebarClose,
  tracks,
  historyTracks = [],
  likedSongs,
  handleNonAuthAction,
  isAuth,
  isCustomStation
}: Props) => {
  const { t } = useTranslation();
  const outsideClickRef = useClickOutside(() => {
    if (sidebarState) {
      onSidebarClose()
    }
  });

  return (
    <div
      ref={outsideClickRef}
      className={cn("sidebar", { "sidebar_opened": sidebarState})}
    >
      <div className="sidebar__content">
        <TopTracks
          isVisible={tracks.length > 0}
          items={tracks}
          likedSongs={likedSongs}
          handleNonAuthAction={handleNonAuthAction}
          isAuth={isAuth}
          title={isCustomStation ? `${t("myTracks")}` : `${t("top")} ${tracks.length}`}
          listType={isCustomStation ? "personal" : "top"}
          headerChildren={<SidebarCloseBtn action={onSidebarClose}/>}
        />
        <LastTracks
          isVisible={!!historyTracks && !isCustomStation}
          items={historyTracks}
          likedSongs={likedSongs}
          handleNonAuthAction={handleNonAuthAction}
          isAuth={isAuth}
          title={t("last5Tracks")}
          listType="history"
          headerChildren={<SidebarCloseBtn action={onSidebarClose}/>}
        />
      </div>
    </div>
  );
});