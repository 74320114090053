import { Layout } from "./Layout";
import { Container } from "./Container";
import { Equalizer } from "../../features/Equalizer/Equalizer";
import { TimeLine } from "./ui/TimeLine/TimeLine";
import { TrackInfo } from "./ui/TrackInfo/TrackInfo";
import { ControlPanel } from "./ui/ControlPanel/ControlPanel";
import { VolumeBar } from "./ui/VolumeBar/VolumeBar";
import { Actions } from "./Actions/Actions";

/**
 * Композиция компонентов плеера
 */
export const Player = {
  Layout,
  Container,
  Equalizer,
  TimeLine,
  TrackInfo,
  ControlPanel,
  VolumeBar,
  Actions
}

export { runPlayAfterSwitch } from "./model/runPlayAfterSwitch";