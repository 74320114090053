import "./Container.css";

import { observer } from "mobx-react-lite";

type Props = {
  layoutType: "regular" | "small";
  equalizer: JSX.Element;
  timeline: JSX.Element;
  trackInfo: JSX.Element;
  controlPanel: JSX.Element;
  volumeBar: JSX.Element;
  options: JSX.Element;
};

export const Container = observer(({
  layoutType,
  equalizer,
  timeline,
  trackInfo,
  controlPanel,
  volumeBar,
  options,
}: Props) => {
  return (
    <div className="player-container">
      <div className="player-container__equalizer">
        {equalizer}
      </div>
      <div className={`player-container__body player-container__body_${layoutType}`}>
        {timeline}
        <div className="player-container__main">
          <div className="player-container__track-info">
            {trackInfo}
          </div>
          {controlPanel}
          <div className="player-container__section">
            <div className="player-container__volume-bar">
              {volumeBar}
            </div>
            <div className="player-container__options">
              {options}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});