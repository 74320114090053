import "./Actions.css";

import { observer } from "mobx-react-lite";
import { NowPlayingModel } from "entities/NowPlaying";
import { FavouriteModel } from "entities/Favourite";
import { makeFromObjectMap } from "shared/lib/makeFromObjectMap";
import { rmdIcons } from "shared/icons";

import { IPlayerActionsMap, IPlayerAction } from "./types";
import { ControlBtn } from "../ui/ControlBtn/ControlBtn";
import {StationSwitchStore} from "../../Stations";
import {useNavigate} from "react-router-dom";

type Props = {
  model: NowPlayingModel;
  likedSongs: FavouriteModel[];
  handleNonAuthAction: () => void;
  isAuth: boolean;
};

/**
 * Компонент с экшенами для трека (лайк, скачивание и т.д.)
 */

export const Actions = observer(function SongControls({
  model,
  likedSongs,
  handleNonAuthAction,
  isAuth,
}: Props) {
  const stationSwitchStore = StationSwitchStore.getShared();
  const navigate = useNavigate();
  const getStationId = () => likedSongs
    .find(song => String(song.id) === model.song?.id)?.stationId;

  const handleLike = async (model: NowPlayingModel) => {
    if (!isAuth) {
      handleNonAuthAction();
      return;
    }
    await model.onToggleLike(getStationId());
  };

  const songActionsMap: IPlayerActionsMap = {
    share: {
      action: () => {},
      checkIsActive: () => false,
      isAvailable: false,
    },
    like: {
      action: handleLike,
      checkIsActive: () => likedSongs.some(item=> (item.songId === model.song?.id)),
      isAvailable: isAuth,
      name: "like"
    },
    download: {
      action: () => {},
      checkIsActive: () => false,
      // Временно отключена функция
      isAvailable: false && stationSwitchStore.currentStation?.icon !== 'personal',
      name: "download"
    }
  };
  const songActions = makeFromObjectMap(songActionsMap);
  const onItemAction = (item: IPlayerAction, fileFormat?: string) => item.action(model, fileFormat);

  return (
    <div className="player-actions">
      {songActions.map(({ key, item }) => item.name !== "download" || stationSwitchStore.currentStation?.icon === 'personal' ? <ControlBtn
        key={key}
        isAvailable={item.isAvailable}
        checkIsActive={item.checkIsActive}
        icon={key as keyof typeof rmdIcons}
        action={item.name === 'download' ? () => onItemAction(item, 'pls') : () => onItemAction(item)}
        isAuth={isAuth}
        model={model}
        actionName={item.name}
      /> :
        <a href={`https://rmd.today/${stationSwitchStore.currentStation?.icon}.pls`} download>
          <ControlBtn
            key={key}
            isAvailable={item.isAvailable}
            checkIsActive={item.checkIsActive}
            icon={key as keyof typeof rmdIcons}
            action={item.name === 'download' ? () => onItemAction(item, 'pls') : () => onItemAction(item)}
            isAuth={isAuth}
            model={model}
            actionName={item.name}
          />
        </a>
      )}
    </div>
  );
});