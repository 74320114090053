import { action, makeObservable, observable } from "mobx";

import { EqualizerVisualStore } from "features/Equalizer/EqualizerVisualStore";

import { IDelegate } from "../types/IDelegate";

type PlayerStateType = false | true;

export class PlayerControlStore {
  private static shared: PlayerControlStore | null;

  isPlaying: boolean = false;

  delegate: IDelegate;

  audioRef: any;

  svgRef: any;

  constructor(delegate: IDelegate) {
    this.delegate = delegate;

    makeObservable(this, {
      isPlaying: observable,
      setPlaying: action
    });
  }

  static getShared(delegateStore: EqualizerVisualStore): PlayerControlStore {
    if (this.shared) {
        return this.shared;
    }
    this.shared = new PlayerControlStore(delegateStore);
    return this.shared;
  }

  checkPlaying = () => {
    if (!this.audioRef) return false;

    return this.audioRef.current &&
      this.audioRef.current.currentTime > 0 &&
      !this.audioRef.current.paused &&
      !this.audioRef.current.ended;
  }

  togglePlay = (play: boolean, url: string = "") => {
      if (!this.audioRef) return;

      if (this.checkPlaying() && !play) {
          this.audioRef.current?.pause();
          this.delegate.clearEqualizer();
          this.setPlaying(false);
      } else {
          if (this.audioRef.current) {
              this.audioRef.current.src = url;
              this.audioRef.current.play().catch((err: { code: number }) => {
                  if (err.code != 20) {
                      console.log("err", err);
                  }
              });
              this.delegate.init(this.audioRef, this.svgRef);
              this.delegate.drawBars();
              this.setPlaying(true);
          }

      }
  };

  setPlaying(state: PlayerStateType) {
    this.isPlaying = state;
  }
}