const free = <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.4">
<g opacity="0.31" filter="url(#filter0_f_803_6602)">
<ellipse cx="84.3381" cy="79.7502" rx="39.4167" ry="46.75" fill="#00BAF5"/>
</g>
<path opacity="0.1" d="M126.34 59.158C135.964 80.3641 122.464 107.223 96.1871 119.149C69.91 131.075 40.8059 123.552 31.1814 102.346C21.557 81.1396 35.0567 54.2807 61.3338 42.3547C87.611 30.4288 116.715 37.9518 126.34 59.158Z" fill="#00BAF5"/>
<path opacity="0.1" d="M124.506 55.4912C134.131 76.6974 120.631 103.556 94.3536 115.482C68.0765 127.408 38.9724 119.885 29.3479 98.679C19.7235 77.4729 33.2232 50.614 59.5004 38.688C85.7775 26.762 114.882 34.2851 124.506 55.4912Z" fill="#00BAF5"/>
<path opacity="0.1" d="M108.161 55.062C117.367 75.3461 103.528 101.458 77.2505 113.384C50.9733 125.31 22.2085 118.534 13.0025 98.2498C3.79648 77.9656 17.6354 51.8542 43.9126 39.9282C70.1898 28.0022 98.9546 34.7779 108.161 55.062Z" fill="#00BAF5"/>
<path opacity="0.1" d="M109.082 56.8935C118.288 77.1777 104.449 103.289 78.1714 115.215C51.8942 127.141 23.1294 120.365 13.9234 100.081C4.71738 79.7972 18.5563 53.6857 44.8335 41.7597C71.1107 29.8338 99.8755 36.6094 109.082 56.8935Z" fill="#00BAF5"/>
<g opacity="0.3" filter="url(#filter1_f_803_6602)">
<path d="M118.248 49.5613C127.455 69.8454 113.616 95.9569 87.3384 107.883C61.0612 119.809 32.2964 113.033 23.0904 92.7491C13.8844 72.4649 27.7233 46.3534 54.0005 34.4275C80.2777 22.5015 109.042 29.2771 118.248 49.5613Z" fill="#00BAF5"/>
</g>
<ellipse opacity="0.2" cx="82.5049" cy="79.7502" rx="41.25" ry="46.75" fill="#00BAF5"/>
<ellipse opacity="0.2" cx="86.1714" cy="79.7502" rx="41.25" ry="46.75" fill="#00BAF5"/>
</g>
<path d="M103.544 77.595C103.222 81.4904 100.733 84.8637 96.5561 87.1527C92.5402 89.3614 87.4803 90.4055 82.4605 90.285C85.3519 87.6747 87.0386 84.4219 87.3598 80.9683C87.7614 75.9887 85.3921 71.2099 80.6534 67.3948C77.9628 65.2664 74.8304 63.5798 71.417 62.3349C80.292 59.7648 91.456 61.4916 98.323 67.0334C102.018 70.0051 103.905 73.7398 103.544 77.595Z" fill="url(#paint0_radial_803_6602)"/>
<path d="M95.0301 39.5508C95.6726 47.3415 90.1307 54.1684 82.4605 55.092C82.4204 55.092 82.4204 55.092 82.3802 55.092H82.2597C82.0188 55.092 81.7778 55.092 81.577 55.1723C77.6817 55.3731 74.1076 54.1282 71.417 51.8392C75.5533 48.1447 77.9226 42.6028 77.4407 36.5791C77.1596 33.3263 76.0352 30.3545 74.3485 27.8246C75.8746 27.0616 77.6415 26.5797 79.4486 26.419C87.3197 25.7364 94.3474 31.5995 95.0301 39.5508Z" fill="url(#paint1_radial_803_6602)"/>
<path d="M77.0927 67.1265C65.8886 59.6571 47.6165 59.6571 36.3321 67.1265C31.232 70.5399 28.4209 75.1581 28.4209 80.0976C28.4209 85.0371 31.232 89.6151 36.2919 92.9884C41.9141 96.7633 49.3032 98.6507 56.6923 98.6507C64.0814 98.6507 71.4706 96.7633 77.0927 92.9884C82.1526 89.575 84.9637 84.9969 84.9637 80.0173C84.9236 75.0778 82.1526 70.4998 77.0927 67.1265Z" fill="url(#paint2_radial_803_6602)"/>
<path d="M56.6924 18.3333C46.1709 18.3333 37.6172 26.887 37.6172 37.4084C37.6172 47.7291 45.689 56.082 56.2105 56.4434C56.5317 56.4033 56.853 56.4033 57.0939 56.4434C57.1743 56.4434 57.2144 56.4434 57.2947 56.4434C57.3349 56.4434 57.3349 56.4434 57.3751 56.4434C67.6556 56.082 75.7274 47.7291 75.7675 37.4084C75.7675 26.887 67.2138 18.3333 56.6924 18.3333Z" fill="url(#paint3_radial_803_6602)"/>
<defs>
<filter id="filter0_f_803_6602" x="40.9214" y="29.0002" width="86.8335" height="101.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_803_6602"/>
</filter>
<filter id="filter1_f_803_6602" x="16.2412" y="24.543" width="108.856" height="93.2244" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_803_6602"/>
</filter>
<radialGradient id="paint0_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(87.5022 75.7315) rotate(90) scale(14.5628 16.0852)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint1_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.2488 40.7792) rotate(90) scale(14.4142 11.8319)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint2_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.7399 75.8948) rotate(152.447) scale(34.7265 52.888)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint3_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(66.0626 30.3807) rotate(135) scale(28.3962 28.4261)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
</defs>
</svg>;

const freeActive = <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_803_6602)">
<g opacity="0.4">
<g opacity="0.31" filter="url(#filter0_f_803_6602)">
<path d="M91.4281 131.357C66.03 136.002 42.2662 122.407 38.3503 100.993C34.4343 79.5789 51.8491 58.4542 77.2472 53.8097C102.645 49.1652 126.409 62.7597 130.325 84.1738C134.241 105.588 116.826 126.713 91.4281 131.357Z" fill="#00BAF5"/>
</g>
<path opacity="0.1" d="M115.34 46.325C124.964 67.5311 111.464 94.39 85.1871 106.316C58.91 118.242 29.8059 110.719 20.1814 89.5128C10.557 68.3066 24.0567 41.4477 50.3338 29.5217C76.611 17.5958 105.715 25.1188 115.34 46.325Z" fill="#00BAF5"/>
<path opacity="0.1" d="M124.506 55.4912C134.131 76.6974 120.631 103.556 94.3536 115.482C68.0765 127.408 38.9724 119.885 29.3479 98.679C19.7235 77.4729 33.2232 50.614 59.5004 38.688C85.7775 26.762 114.882 34.2851 124.506 55.4912Z" fill="#00BAF5"/>
<path opacity="0.1" d="M108.161 55.062C117.367 75.3461 103.528 101.458 77.2505 113.384C50.9733 125.31 22.2085 118.534 13.0025 98.2498C3.79648 77.9656 17.6354 51.8542 43.9126 39.9282C70.1898 28.0022 98.9546 34.7779 108.161 55.062Z" fill="#00BAF5"/>
<path opacity="0.1" d="M109.082 56.8935C118.288 77.1777 104.449 103.289 78.1719 115.215C51.8947 127.141 23.1299 120.365 13.9239 100.081C4.71787 79.7972 18.5568 53.6857 44.834 41.7597C71.1111 29.8338 99.876 36.6094 109.082 56.8935Z" fill="#00BAF5"/>
<g opacity="0.3" filter="url(#filter1_f_803_6602)">
<path d="M99.6019 93.4279C90.1067 113.578 61.248 119.942 35.144 107.641C9.04012 95.3407 -4.42392 69.0339 5.07128 48.8835C14.5665 28.7331 43.4253 22.3696 69.5292 34.6702C95.6331 46.9708 109.097 73.2776 99.6019 93.4279Z" fill="#00BAF5"/>
</g>
<path opacity="0.2" d="M93.181 39.9056C118.121 46.5881 133.558 69.8444 127.662 91.8498C121.765 113.855 96.768 126.277 71.8284 119.594C46.8889 112.912 31.4513 89.6557 37.3477 67.6503C43.244 45.6448 68.2415 33.2231 93.181 39.9056Z" fill="#00BAF5"/>
<ellipse opacity="0.2" cx="97.1714" cy="66.9167" rx="41.25" ry="46.75" fill="#00BAF5"/>
</g>
<path d="M103.544 77.595C103.222 81.4904 100.733 84.8637 96.5561 87.1527C92.5402 89.3614 87.4803 90.4055 82.4605 90.285C85.3519 87.6747 87.0386 84.4219 87.3598 80.9683C87.7614 75.9887 85.3921 71.2099 80.6534 67.3948C77.9628 65.2664 74.8304 63.5798 71.417 62.3349C80.292 59.7648 91.456 61.4916 98.323 67.0334C102.018 70.0051 103.905 73.7398 103.544 77.595Z" fill="url(#paint0_radial_803_6602)"/>
<path d="M95.0301 39.5508C95.6726 47.3415 90.1307 54.1684 82.4605 55.092C82.4204 55.092 82.4204 55.092 82.3802 55.092H82.2597C82.0188 55.092 81.7778 55.092 81.577 55.1723C77.6817 55.3731 74.1076 54.1282 71.417 51.8392C75.5533 48.1447 77.9226 42.6028 77.4407 36.5791C77.1596 33.3263 76.0352 30.3545 74.3485 27.8246C75.8746 27.0616 77.6415 26.5797 79.4486 26.419C87.3197 25.7364 94.3474 31.5995 95.0301 39.5508Z" fill="url(#paint1_radial_803_6602)"/>
<path d="M77.0927 67.1265C65.8886 59.6571 47.6165 59.6571 36.3321 67.1265C31.232 70.5399 28.4209 75.1581 28.4209 80.0976C28.4209 85.0371 31.232 89.6151 36.2919 92.9884C41.9141 96.7633 49.3032 98.6507 56.6923 98.6507C64.0814 98.6507 71.4706 96.7633 77.0927 92.9884C82.1526 89.575 84.9637 84.9969 84.9637 80.0173C84.9236 75.0778 82.1526 70.4998 77.0927 67.1265Z" fill="url(#paint2_radial_803_6602)"/>
<path d="M56.6924 18.3333C46.1709 18.3333 37.6172 26.887 37.6172 37.4084C37.6172 47.7291 45.689 56.082 56.2105 56.4434C56.5317 56.4033 56.853 56.4033 57.0939 56.4434C57.1743 56.4434 57.2144 56.4434 57.2947 56.4434C57.3349 56.4434 57.3349 56.4434 57.3751 56.4434C67.6556 56.082 75.7274 47.7291 75.7675 37.4084C75.7675 26.887 67.2138 18.3333 56.6924 18.3333Z" fill="url(#paint3_radial_803_6602)"/>
</g>
<defs>
<filter id="filter0_f_803_6602" x="33.8003" y="48.8999" width="101.075" height="87.3669" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_803_6602"/>
</filter>
<filter id="filter1_f_803_6602" x="-1.97217" y="24.4033" width="108.618" height="93.5049" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_803_6602"/>
</filter>
<radialGradient id="paint0_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(87.5022 75.7315) rotate(90) scale(14.5628 16.0852)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint1_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.2488 40.7792) rotate(90) scale(14.4142 11.8319)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint2_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.7399 75.8948) rotate(152.447) scale(34.7265 52.888)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<radialGradient id="paint3_radial_803_6602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(66.0626 30.3807) rotate(135) scale(28.3962 28.4261)">
<stop offset="0.0625" stopColor="#00BAF5"/>
<stop offset="1" stopColor="#00BAF5" stopOpacity="0.38"/>
</radialGradient>
<clipPath id="clip0_803_6602">
<rect width="154" height="154" fill="white"/>
</clipPath>
</defs>
</svg>;

const medium = <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.6">
<g opacity="0.31" filter="url(#filter0_f_813_8517)">
<ellipse cx="84.7964" cy="83.8345" rx="33.8721" ry="40.1739" fill="#FFB800"/>
</g>
<path opacity="0.1" d="M120.889 66.1388C129.16 84.362 117.559 107.443 94.9784 117.691C72.3975 127.94 47.3874 121.475 39.1168 103.252C30.8461 85.0284 42.4469 61.9476 65.0278 51.6992C87.6086 41.4508 112.619 47.9156 120.889 66.1388Z" fill="#FF9E67"/>
<path opacity="0.1" d="M119.313 62.9874C127.584 81.2106 115.983 104.291 93.4022 114.54C70.8213 124.788 45.8112 118.323 37.5406 100.1C29.27 81.877 40.8707 58.7962 63.4516 48.5478C86.0325 38.2994 111.043 44.7643 119.313 62.9874Z" fill="#FF9E67"/>
<path opacity="0.1" d="M93.5027 66.0363C101.414 83.4672 89.5215 105.906 66.9406 116.154C44.3598 126.402 19.6412 120.58 11.7301 103.149C3.81909 85.7182 15.7113 63.2798 38.2922 53.0314C60.8731 42.783 85.5917 48.6055 93.5027 66.0363Z" fill="#FF9E67"/>
<path opacity="0.1" d="M87.1682 73.2758C95.0793 90.7067 83.187 113.145 60.6062 123.394C38.0253 133.642 13.3067 127.819 5.39566 110.389C-2.51538 92.9577 9.37686 70.5193 31.9577 60.2709C54.5386 50.0225 79.2572 55.845 87.1682 73.2758Z" fill="#FF9E67"/>
<g opacity="0.3" filter="url(#filter1_f_813_8517)">
<path d="M101.436 63.2487C109.347 80.6796 97.4551 103.118 74.8742 113.366C52.2934 123.615 27.5748 117.792 19.6637 100.361C11.7527 82.9306 23.6449 60.4922 46.2258 50.2438C68.8067 39.9954 93.5253 45.8179 101.436 63.2487Z" fill="#FF9E67"/>
</g>
<ellipse opacity="0.2" cx="92.5906" cy="77.6739" rx="35.4475" ry="40.1739" fill="#FF9E67"/>
<path opacity="0.2" d="M137.883 84.3123C129.219 104.738 107.584 115.099 89.5618 107.454C71.5391 99.8084 63.9529 77.0525 72.6173 56.6269C81.2818 36.2012 102.916 25.8405 120.939 33.4857C138.961 41.1308 146.548 63.8867 137.883 84.3123Z" fill="#FF9E67"/>
</g>
<path d="M115.501 83.1155C114.412 87.1193 110.016 89.9665 103.483 91.1879C96.9874 92.4022 88.5526 91.9663 79.7294 89.5665C70.9061 87.1666 63.4125 83.2702 58.427 78.9332C53.4123 74.5707 51.0643 69.8894 52.1533 65.8855C53.2424 61.8816 57.638 59.0345 64.1715 57.813C70.6669 56.5987 79.1017 57.0346 87.925 59.4345C96.7482 61.8343 104.242 65.7308 109.227 70.0678C114.242 74.4302 116.59 79.1116 115.501 83.1155Z" stroke="url(#paint0_linear_813_8517)"/>
<path d="M138.795 93.1806C134.917 107.437 107.872 112.494 78.3867 104.474C48.9019 96.4544 28.1433 78.3959 32.021 64.1392" stroke="url(#paint1_linear_813_8517)"/>
<path d="M103.339 77.3939C102.74 79.5964 100.261 81.2191 96.4173 81.9098C92.6148 82.5931 87.6561 82.3209 82.454 80.906C77.2518 79.491 72.8384 77.2141 69.9059 74.6989C66.9416 72.1566 65.6263 69.5019 66.2253 67.2995C66.8244 65.097 69.3031 63.4743 73.1467 62.7836C76.9492 62.1003 81.9079 62.3725 87.11 63.7874C92.3122 65.2023 96.7256 67.4793 99.6582 69.9944C102.622 72.5368 103.938 75.1914 103.339 77.3939Z" stroke="url(#paint2_linear_813_8517)"/>
<path d="M51.7852 50.0971C51.7852 49.3703 52.3061 48.7482 53.0215 48.6204L57.1643 47.8806C58.0837 47.7164 58.928 48.4233 58.928 49.3573V53.9629C58.928 54.6896 58.4071 55.3118 57.6917 55.4395L53.5488 56.1793C52.6294 56.3435 51.7852 55.6366 51.7852 54.7027V50.0971Z" fill="url(#paint3_linear_813_8517)"/>
<path d="M32.1421 55.4542C32.1421 54.7275 32.663 54.1053 33.3784 53.9776L37.5213 53.2378C38.4407 53.0736 39.2849 53.7805 39.2849 54.7144V59.32C39.2849 60.0468 38.764 60.6689 38.0486 60.7967L33.9058 61.5365C32.9864 61.7007 32.1421 60.9938 32.1421 60.0598V55.4542Z" fill="url(#paint4_linear_813_8517)"/>
<path d="M44.6431 75.0057C44.6431 73.9289 45.4956 73.0454 46.5717 73.007L56.8574 72.6397C57.9892 72.5992 58.9288 73.5058 58.9288 74.6384V84.4113C58.9288 85.4881 58.0763 86.3716 57.0002 86.4101L46.7144 86.7774C45.5826 86.8178 44.6431 85.9112 44.6431 84.7787V75.0057Z" fill="url(#paint5_linear_813_8517)"/>
<path d="M82.1431 90.3838C82.1431 88.7687 83.4218 87.4434 85.036 87.3858L96.8931 86.9623C98.5909 86.9017 100 88.2615 100 89.9604V101.176C100 102.791 98.7214 104.116 97.1073 104.174L85.2501 104.597C83.5524 104.658 82.1431 103.298 82.1431 101.599V90.3838Z" fill="url(#paint6_linear_813_8517)"/>
<path d="M110.713 71.3066C110.713 70.2299 111.566 69.3463 112.642 69.3079L119.356 69.0681C120.488 69.0277 121.428 69.9343 121.428 71.0668V77.3959C121.428 78.4727 120.575 79.3562 119.499 79.3947L112.785 79.6344C111.653 79.6749 110.713 78.7683 110.713 77.6357V71.3066Z" fill="url(#paint7_linear_813_8517)"/>
<defs>
<filter id="filter0_f_813_8517" x="48.8972" y="41.6335" width="71.7983" height="84.4019" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.01355" result="effect1_foregroundBlur_813_8517"/>
</filter>
<filter id="filter1_f_813_8517" x="15.1882" y="43.1599" width="90.7236" height="77.2905" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.01355" result="effect1_foregroundBlur_813_8517"/>
</filter>
<linearGradient id="paint0_linear_813_8517" x1="48.7619" y1="60.7665" x2="112.786" y2="87.1683" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.328125" stopColor="white"/>
<stop offset="0.614583" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_813_8517" x1="135.714" y1="102.923" x2="25.8931" y2="50.2441" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.213542" stopColor="white"/>
<stop offset="0.71875" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_813_8517" x1="84.4042" y1="70.6673" x2="81.104" y2="60.1066" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_813_8517" x1="55.3566" y1="47.5657" x2="55.3566" y2="56.4942" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint4_linear_813_8517" x1="35.7135" y1="52.9229" x2="35.7135" y2="61.8514" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint5_linear_813_8517" x1="51.7859" y1="72.5657" x2="42.8574" y2="86.8514" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint6_linear_813_8517" x1="100" y1="86.8513" x2="82.1431" y2="104.708" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint7_linear_813_8517" x1="116.071" y1="68.9941" x2="108.036" y2="79.7084" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
</defs>
</svg>;

const mediumActive = <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.6">
<g opacity="0.31" filter="url(#filter0_f_813_8517)">
<ellipse cx="84.7964" cy="83.8345" rx="33.8721" ry="40.1739" fill="#FFB800"/>
</g>
<path opacity="0.1" d="M120.889 66.1388C129.16 84.362 117.559 107.443 94.9779 117.691C72.397 127.94 47.3869 121.475 39.1163 103.252C30.8457 85.0284 42.4464 61.9476 65.0273 51.6992C87.6081 41.4508 112.618 47.9156 120.889 66.1388Z" fill="#FF9E67"/>
<path opacity="0.1" d="M119.313 62.9874C127.584 81.2106 115.983 104.291 93.4022 114.54C70.8213 124.788 45.8112 118.323 37.5406 100.1C29.27 81.877 40.8707 58.7962 63.4516 48.5478C86.0325 38.2994 111.043 44.7643 119.313 62.9874Z" fill="#FF9E67"/>
<path opacity="0.1" d="M139.578 82.3474C147.489 99.7782 135.597 122.217 113.016 132.465C90.4354 142.714 65.7169 136.891 57.8058 119.46C49.8948 102.029 61.787 79.5908 84.3679 69.3424C106.949 59.094 131.667 64.9165 139.578 82.3474Z" fill="#FF9E67"/>
<path opacity="0.1" d="M130.65 84.1333C138.561 101.564 126.668 124.003 104.088 134.251C81.5067 144.499 56.7881 138.677 48.8771 121.246C40.9661 103.815 52.8583 81.3767 75.4392 71.1283C98.0201 60.8799 122.739 66.7024 130.65 84.1333Z" fill="#FF9E67"/>
<g opacity="0.3" filter="url(#filter1_f_813_8517)">
<path d="M130.65 64.4902C138.561 81.9211 126.669 104.36 104.088 114.608C81.5067 124.856 56.7881 119.034 48.8771 101.603C40.9661 84.1721 52.8583 61.7336 75.4392 51.4852C98.0201 41.2368 122.739 47.0594 130.65 64.4902Z" fill="#FF9E67"/>
</g>
<ellipse opacity="0.2" cx="74.7332" cy="66.9595" rx="35.4475" ry="40.1739" fill="#FF9E67"/>
<path opacity="0.2" d="M92.0638 78.3431C83.3993 98.7688 61.7651 109.129 43.7425 101.484C25.7198 93.8392 18.1335 71.0833 26.798 50.6576C35.4625 30.232 57.0967 19.8713 75.1193 27.5164C93.142 35.1616 100.728 57.9174 92.0638 78.3431Z" fill="#FF9E67"/>
</g>
<path d="M115.501 83.1155C114.412 87.1193 110.016 89.9665 103.483 91.1879C96.9874 92.4022 88.5526 91.9663 79.7294 89.5665C70.9061 87.1666 63.4125 83.2702 58.427 78.9332C53.4123 74.5707 51.0643 69.8894 52.1533 65.8855C53.2424 61.8816 57.638 59.0345 64.1715 57.813C70.6669 56.5987 79.1017 57.0346 87.925 59.4345C96.7482 61.8343 104.242 65.7308 109.227 70.0678C114.242 74.4302 116.59 79.1116 115.501 83.1155Z" stroke="url(#paint0_linear_813_8517)"/>
<path d="M138.795 93.1806C134.917 107.437 107.872 112.494 78.3867 104.474C48.9019 96.4544 28.1433 78.3959 32.021 64.1392" stroke="url(#paint1_linear_813_8517)"/>
<path d="M103.339 77.3939C102.74 79.5964 100.261 81.2191 96.4173 81.9098C92.6148 82.5931 87.6561 82.3209 82.454 80.906C77.2518 79.491 72.8384 77.2141 69.9059 74.6989C66.9416 72.1566 65.6263 69.5019 66.2253 67.2995C66.8244 65.097 69.3031 63.4743 73.1467 62.7836C76.9492 62.1003 81.9079 62.3725 87.11 63.7874C92.3122 65.2023 96.7256 67.4793 99.6582 69.9944C102.622 72.5368 103.938 75.1914 103.339 77.3939Z" stroke="url(#paint2_linear_813_8517)"/>
<path d="M39.2856 54.317C39.2856 53.5903 39.8066 52.9681 40.522 52.8404L44.6648 52.1006C45.5842 51.9364 46.4285 52.6433 46.4285 53.5772V58.1828C46.4285 58.9095 45.9076 59.5317 45.1922 59.6595L41.0493 60.3993C40.1299 60.5635 39.2856 59.8566 39.2856 58.9226V54.317Z" fill="url(#paint3_linear_813_8517)"/>
<path d="M35.7144 77.1871C35.7144 76.3899 36.338 75.7319 37.1341 75.6892L48.4198 75.0847C49.2785 75.0387 50.0001 75.7226 50.0001 76.5825V88.6952C50.0001 89.5657 49.2615 90.2534 48.3932 90.1913L37.1075 89.3852C36.3225 89.3292 35.7144 88.676 35.7144 87.889V77.1871Z" fill="url(#paint4_linear_813_8517)"/>
<path d="M66.0713 93.9744C66.0713 91.8208 67.7763 90.0538 69.9285 89.977L85.1428 89.4336C87.4065 89.3528 89.2856 91.1659 89.2856 93.4311V107.811C89.2856 109.965 87.5805 111.732 85.4283 111.809L70.2141 112.352C67.9504 112.433 66.0713 110.62 66.0713 108.355V93.9744Z" fill="url(#paint5_linear_813_8517)"/>
<path d="M108.929 80.9476C108.929 79.8708 109.781 78.9873 110.857 78.9488L119.357 78.6453C120.489 78.6048 121.429 79.5114 121.429 80.644V88.695C121.429 89.7718 120.576 90.6553 119.5 90.6937L111 90.9973C109.868 91.0377 108.929 90.1311 108.929 88.9986V80.9476Z" fill="url(#paint6_linear_813_8517)"/>
<path d="M96.4287 60.1485C96.4287 59.6101 96.855 59.1683 97.393 59.1491L102.536 58.9655C103.102 58.9452 103.572 59.3985 103.572 59.9648V64.8513C103.572 65.3897 103.145 65.8314 102.607 65.8507L97.4644 66.0343C96.8985 66.0545 96.4287 65.6012 96.4287 65.035V60.1485Z" fill="url(#paint7_linear_813_8517)"/>
<defs>
<filter id="filter0_f_813_8517" x="48.8972" y="41.6335" width="71.7983" height="84.4019" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.01355" result="effect1_foregroundBlur_813_8517"/>
</filter>
<filter id="filter1_f_813_8517" x="44.4016" y="44.4014" width="90.7236" height="77.2905" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.01355" result="effect1_foregroundBlur_813_8517"/>
</filter>
<linearGradient id="paint0_linear_813_8517" x1="48.7619" y1="60.7665" x2="112.786" y2="87.1683" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.328125" stopColor="white"/>
<stop offset="0.614583" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_813_8517" x1="135.714" y1="102.923" x2="25.8931" y2="50.2441" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.213542" stopColor="white"/>
<stop offset="0.71875" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_813_8517" x1="84.4042" y1="70.6673" x2="81.104" y2="60.1066" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_813_8517" x1="42.8571" y1="51.7856" x2="42.8571" y2="60.7142" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint4_linear_813_8517" x1="42.8572" y1="73.2143" x2="42.8572" y2="91.0714" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint5_linear_813_8517" x1="77.6784" y1="89.2856" x2="63.1695" y2="112.5" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint6_linear_813_8517" x1="121.429" y1="78.5713" x2="108.929" y2="91.0713" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
<linearGradient id="paint7_linear_813_8517" x1="100" y1="58.9285" x2="94.6436" y2="66.0713" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFCFB5"/>
<stop offset="0.588542" stopColor="#FF9E67"/>
</linearGradient>
</defs>
</svg>;

const pro = <svg width="135" height="158" viewBox="0 0 135 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.6493 53.2362C45.4333 54.4651 46.3283 55.7196 47.3399 56.9712L38.5417 72.1996C38.4369 72.0214 38.3344 71.8423 38.2347 71.6626C37.4782 70.2994 37.0014 69.1129 36.7976 68.1688C36.6573 67.5187 36.6801 67.1507 36.7133 66.9723L44.6493 53.2362Z" stroke="url(#paint0_linear_813_8839)" strokeOpacity="0.3" strokeWidth="11" strokeMiterlimit="2.2042"/>
<path d="M110.99 71.5982C104.593 74.9518 94.3886 72.9409 88.6045 70.7502C84.3822 80.0506 76.7158 90.4978 73.5663 94.2891C79.9673 99.944 91.5775 101.962 96.3451 96.9469L110.99 71.5982Z" fill="#61A0FF" fillOpacity="0.4"/>
<path d="M81.6073 92.863C84.6303 88.726 88.4083 83.094 91.4447 77.4C93.8128 78.0104 96.4257 78.4988 99.0867 78.7322C99.5366 78.7717 99.9951 78.8045 100.46 78.8293L92.0198 93.4389C91.1806 94.0105 89.3564 94.5835 86.3092 94.1692C84.7226 93.9535 83.1099 93.4986 81.6073 92.863Z" stroke="url(#paint1_linear_813_8839)" strokeOpacity="0.3" strokeWidth="11" strokeMiterlimit="2.2042"/>
<g filter="url(#filter0_d_813_8839)">
<path d="M76.4276 33.0696C80.4898 29.9336 86.044 29.5623 90.4875 32.1295C94.9311 34.6968 97.3836 39.694 96.6959 44.7795L96.2717 47.9162L93.4124 58.7092C89.6676 72.8443 82.2249 85.7266 71.8498 96.031L56.0058 111.767L38.7188 101.779L44.4374 80.1936C48.1822 66.0585 55.625 53.1762 66.0001 42.8717L73.922 35.0037L76.4276 33.0696Z" fill="url(#paint2_radial_813_8839)"/>
<path d="M76.4276 33.0696C80.4898 29.9336 86.044 29.5623 90.4875 32.1295C94.9311 34.6968 97.3836 39.694 96.6959 44.7795L96.2717 47.9162L93.4124 58.7092C89.6676 72.8443 82.2249 85.7266 71.8498 96.031L56.0058 111.767L38.7188 101.779L44.4374 80.1936C48.1822 66.0585 55.625 53.1762 66.0001 42.8717L73.922 35.0037L76.4276 33.0696Z" fill="url(#paint3_radial_813_8839)" fillOpacity="0.2"/>
<path d="M77.0386 33.8611C80.7798 30.9731 85.895 30.6311 89.9873 32.9954C94.0796 35.3597 96.3383 39.9619 95.7049 44.6455L95.2891 47.7204L92.4457 58.4531C88.7465 72.4164 81.3942 85.1423 71.1451 95.3215L55.8438 110.519L39.8813 101.296L45.4041 80.4497C49.1034 66.4863 56.4557 53.7605 66.7048 43.5813L74.5825 35.7572L77.0386 33.8611Z" stroke="url(#paint4_linear_813_8839)" strokeWidth="2"/>
</g>
<g filter="url(#filter1_f_813_8839)">
<path d="M93.292 33.7498L90.5773 44.6263L86.9554 54.9786V54.9786C82.1687 68.6599 74.8543 81.3201 65.3927 92.3005L51.074 108.918" stroke="url(#paint5_linear_813_8839)" strokeWidth="3"/>
</g>
<path d="M92.1079 29.3249C95.3675 31.2082 96.5761 38.5245 96.5761 38.5245C96.5761 38.5245 92.6144 35.0611 89.7499 33.4061C86.8855 31.7512 81.9062 30.049 81.9062 30.049C81.9062 30.049 88.8482 27.4416 92.1079 29.3249Z" fill="url(#paint6_linear_813_8839)"/>
<g filter="url(#filter2_f_813_8839)">
<path d="M63.0708 46.215C71.4785 47.0022 89.2522 50.8624 93.0852 60.006" stroke="#D8E2F4" strokeWidth="2"/>
</g>
<g filter="url(#filter3_d_813_8839)">
<g filter="url(#filter4_i_813_8839)">
<ellipse cx="86.0885" cy="56.4228" rx="7.10078" ry="11.9592" transform="rotate(19.9001 86.0885 56.4228)" fill="url(#paint7_linear_813_8839)"/>
</g>
<ellipse cx="86.8733" cy="56.5788" rx="5.85503" ry="9.84144" transform="rotate(19.9001 86.8733 56.5788)" fill="url(#paint8_linear_813_8839)"/>
<g filter="url(#filter5_f_813_8839)">
<rect x="85.5586" y="47.1353" width="2.6574" height="7.22536" transform="rotate(1.78129 85.5586 47.1353)" fill="url(#paint9_linear_813_8839)" fillOpacity="0.54"/>
</g>
<g filter="url(#filter6_f_813_8839)">
<rect x="89.2627" y="45.7048" width="1.38936" height="7.22536" transform="rotate(1.78129 89.2627 45.7048)" fill="url(#paint10_linear_813_8839)" fillOpacity="0.54"/>
</g>
</g>
<circle cx="65.8419" cy="48.3914" r="0.498301" transform="rotate(30.0171 65.8419 48.3914)" fill="#D5E2F5"/>
<circle cx="69.7012" cy="49.1822" r="0.498301" transform="rotate(30.0171 69.7012 49.1822)" fill="#D5E2F5"/>
<circle cx="73.5606" cy="49.9732" r="0.498301" transform="rotate(30.0171 73.5606 49.9732)" fill="#D5E2F5"/>
<circle cx="77.295" cy="50.9801" r="0.498301" transform="rotate(30.0171 77.295 50.9801)" fill="#D5E2F5"/>
<g filter="url(#filter7_d_813_8839)">
<ellipse cx="66.5213" cy="90.4249" rx="2.61608" ry="8.59569" transform="rotate(38.9984 66.5213 90.4249)" fill="url(#paint11_linear_813_8839)"/>
<path d="M68.1659 91.7566C66.6943 93.5739 65.1496 95.0221 63.8539 95.8985C63.2034 96.3385 62.6399 96.6178 62.1969 96.7387C61.7399 96.8634 61.5216 96.7934 61.4267 96.7166C61.3318 96.6398 61.218 96.4408 61.245 95.9678C61.2711 95.5094 61.427 94.9002 61.7221 94.1724C62.3099 92.7227 63.4052 90.9106 64.8768 89.0933C66.3484 87.2759 67.8931 85.8277 69.1887 84.9513C69.8393 84.5113 70.4028 84.2321 70.8457 84.1112C71.3028 83.9865 71.521 84.0564 71.6159 84.1333C71.7108 84.2101 71.8246 84.4091 71.7977 84.882C71.7716 85.3405 71.6156 85.9497 71.3205 86.6775C70.7328 88.1271 69.6374 89.9392 68.1659 91.7566Z" stroke="url(#paint12_linear_813_8839)"/>
</g>
<path d="M79.3165 101.636L79.2855 87.3783C79.2855 87.3783 76.4767 91.1506 73.7534 93.9654C71.2534 96.5494 65.7428 101.854 65.7428 101.854L69.4541 108.44C71.2448 111.617 75.8282 111.594 77.5866 108.399C78.7264 106.327 79.3216 104.001 79.3165 101.636Z" fill="url(#paint13_linear_813_8839)"/>
<path d="M56.5961 110.974L61.8141 105.915C59.8968 102.236 57.0287 99.4095 52.7556 96.9407C48.4825 94.4719 44.9251 93.5859 40.7801 93.7625L38.6638 100.728C38.6842 100.692 38.703 100.659 38.72 100.629C39.6837 98.9611 44.2026 100.344 49.141 103.197C54.0794 106.05 57.5673 109.293 56.6035 110.961C56.6011 110.966 56.5986 110.97 56.5961 110.974Z" fill="url(#paint14_linear_813_8839)"/>
<path d="M42.938 93.8582L41.4893 100.071" stroke="#73B4F4" strokeWidth="3"/>
<path d="M47.125 94.5505L44.9161 100.756" stroke="#77A6F7" strokeWidth="3"/>
<path d="M51.3867 96.4373L48.2607 102.113" stroke="#7D99F6" strokeWidth="3"/>
<path d="M55.2841 98.6891L51.3457 104.183" stroke="#747CEF" strokeWidth="3"/>
<path d="M58.454 101.672L54.1293 106.51" stroke="#5049E3" strokeWidth="3"/>
<path d="M61.25 105.301L56.1952 109.286" stroke="#4433DA" strokeWidth="3"/>
<g filter="url(#filter8_i_813_8839)">
<path d="M56.6034 110.961C55.6397 112.629 51.1043 111.237 46.1659 108.384C41.2275 105.531 37.7561 102.297 38.7198 100.629C39.6835 98.961 44.2812 100.245 49.2196 103.098C54.158 105.952 57.5671 109.293 56.6034 110.961Z" fill="#262D79"/>
</g>
<g filter="url(#filter9_ii_813_8839)">
<path d="M34.2818 87.2545L42.5729 87.1732C42.5729 87.1732 46.5784 82.345 48.0522 78.6852C49.512 75.0604 48.4068 69.1068 48.4068 69.1068L36.1556 75.3381C33.7795 76.5466 31.805 78.4184 30.4714 80.7267C28.7859 83.644 30.9127 87.2876 34.2818 87.2545Z" fill="#5445E8"/>
</g>
<path d="M42.0384 92.3314C41.0125 92.3142 41.9672 88.8854 42.5728 87.1731C44.1884 85.3729 46.8569 80.92 47.9276 78.9009C47.6016 82.4533 43.3208 92.353 42.0384 92.3314Z" fill="url(#paint15_linear_813_8839)"/>
<path d="M96.5155 96.9014C100.646 89.7527 90.2785 76.0334 73.3597 66.2586C65.8391 61.9136 58.2899 59.0794 51.8608 57.8992C43.8266 56.4244 37.5415 57.5328 35.2472 61.5038" stroke="url(#paint16_linear_813_8839)" strokeWidth="4"/>
<g filter="url(#filter10_f_813_8839)">
<circle cx="70.6006" cy="64.8083" r="1.37033" transform="rotate(30.0171 70.6006 64.8083)" fill="white"/>
</g>
<path d="M46.9793 122.378C46.1056 123.89 43.6484 125.278 41.2228 126.343C38.1262 127.703 34.7073 125.727 34.3386 122.365C34.0499 119.732 34.0246 116.91 34.8983 115.398C36.8257 112.062 41.0926 110.92 44.4287 112.847C47.7648 114.775 48.9068 119.042 46.9793 122.378Z" fill="url(#paint17_linear_813_8839)"/>
<path d="M45.6341 119.228C45.3764 119.674 44.9449 120.117 44.4145 120.542C41.4965 122.88 37.2014 120.399 37.77 116.703C37.8733 116.031 38.0415 115.436 38.2992 114.99C39.6589 112.636 42.4032 111.677 44.4287 112.847C46.4542 114.018 46.9939 116.874 45.6341 119.228Z" fill="url(#paint18_linear_813_8839)"/>
<g filter="url(#filter11_d_813_8839)">
<circle cx="43.0577" cy="115.22" r="2.74065" transform="rotate(30.0171 43.0577 115.22)" fill="url(#paint19_linear_813_8839)"/>
</g>
<defs>
<filter id="filter0_d_813_8839" x="0.71875" y="0.432617" width="134.092" height="157.334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="19"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.5 0 0 0 0 1 0 0 0 0.23 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter1_f_813_8839" x="47.9375" y="31.3865" width="48.8101" height="80.5103" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter2_f_813_8839" x="60.9775" y="43.2195" width="35.0298" height="19.1731" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter3_d_813_8839" x="74.2676" y="44.9185" width="29.6421" height="37.0085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="7"/>
<feGaussianBlur stdDeviation="3.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.439216 0 0 0 0 0.615686 0 0 0 0 0.866667 0 0 0 0.35 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter4_i_813_8839" x="78.2676" y="42.9185" width="15.6421" height="25.0085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0374306 0 0 0 0 0.206613 0 0 0 0 0.641667 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
</filter>
<filter id="filter5_f_813_8839" x="82.334" y="44.1353" width="8.88086" height="13.3044" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter6_f_813_8839" x="86.0381" y="42.7048" width="7.61328" height="13.2651" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter7_d_813_8839" x="56.7412" y="83.5432" width="25.5605" height="27.7634" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="7"/>
<feGaussianBlur stdDeviation="3.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.292639 0 0 0 0 0.335042 0 0 0 0 0.716667 0 0 0 0.22 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter8_i_813_8839" x="38.5586" y="99.8875" width="18.208" height="15.792" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
</filter>
<filter id="filter9_ii_813_8839" x="21.8809" y="68.1067" width="26.9268" height="33.1479" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="15"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 0.470588 0 0 0 0 0.968627 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-1"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.105882 0 0 0 0 0.788235 0 0 0 1 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_813_8839" result="effect2_innerShadow_813_8839"/>
</filter>
<filter id="filter10_f_813_8839" x="66.23" y="60.4377" width="8.74121" height="8.74121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter11_d_813_8839" x="29.3164" y="105.479" width="27.4824" height="27.4822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.422979 0 0 0 0 0.123438 0 0 0 0 0.658333 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<linearGradient id="paint0_linear_813_8839" x1="52.8555" y1="56.4266" x2="32.0737" y2="66.0007" gradientUnits="userSpaceOnUse">
<stop stopColor="#1DFFBB"/>
<stop offset="1" stopColor="#0F6AF2"/>
</linearGradient>
<linearGradient id="paint1_linear_813_8839" x1="104.78" y1="79.5868" x2="84.2543" y2="93.117" gradientUnits="userSpaceOnUse">
<stop stopColor="#06C43B"/>
<stop offset="1" stopColor="#0F6AF2"/>
</linearGradient>
<radialGradient id="paint2_radial_813_8839" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.6695 104.683) rotate(-69.7496) scale(77.1084 38.7771)">
<stop stopColor="#79A2DD"/>
<stop offset="0.703125" stopColor="white"/>
</radialGradient>
<radialGradient id="paint3_radial_813_8839" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.5383 101.39) rotate(-48.9457) scale(46.1999 23.2335)">
<stop stopColor="#A8C3E8" stopOpacity="0.47"/>
<stop offset="1" stopColor="#D1E0F3"/>
</radialGradient>
<linearGradient id="paint4_linear_813_8839" x1="71.7886" y1="35.8573" x2="64.0621" y2="101.027" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_813_8839" x1="97.2831" y1="36.0556" x2="54.1578" y2="110.699" gradientUnits="userSpaceOnUse">
<stop stopColor="#F9FBFE"/>
<stop offset="1" stopColor="#6491D1"/>
</linearGradient>
<linearGradient id="paint6_linear_813_8839" x1="96.931" y1="38.7296" x2="81.9062" y2="30.049" gradientUnits="userSpaceOnUse">
<stop stopColor="#4839D3"/>
<stop offset="1" stopColor="#469DE7"/>
</linearGradient>
<linearGradient id="paint7_linear_813_8839" x1="86.0885" y1="44.4636" x2="83.4724" y2="67.7592" gradientUnits="userSpaceOnUse">
<stop offset="0.489694" stopColor="#ECF4FE"/>
<stop offset="1" stopColor="#BCD7FA"/>
</linearGradient>
<linearGradient id="paint8_linear_813_8839" x1="84.2573" y1="48.1076" x2="88.3755" y2="64.4252" gradientUnits="userSpaceOnUse">
<stop offset="0.489694" stopColor="#59C3EF"/>
<stop offset="1" stopColor="#408FEC"/>
</linearGradient>
<linearGradient id="paint9_linear_813_8839" x1="86.8873" y1="47.1353" x2="86.8873" y2="54.3606" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_813_8839" x1="89.9574" y1="45.7048" x2="89.9574" y2="52.9302" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_813_8839" x1="64.2327" y1="85.9273" x2="68.6378" y2="91.7366" gradientUnits="userSpaceOnUse">
<stop stopColor="#59C7F1"/>
<stop offset="1" stopColor="#4394ED"/>
</linearGradient>
<linearGradient id="paint12_linear_813_8839" x1="70.0626" y1="83.8862" x2="62.4127" y2="96.7784" gradientUnits="userSpaceOnUse">
<stop stopColor="#68C2EA"/>
<stop offset="1" stopColor="#3386EB"/>
</linearGradient>
<linearGradient id="paint13_linear_813_8839" x1="70.5439" y1="96.2833" x2="78.0947" y2="107.12" gradientUnits="userSpaceOnUse">
<stop offset="0.364583" stopColor="#001DD7"/>
<stop offset="0.59375" stopColor="#4040D9"/>
</linearGradient>
<linearGradient id="paint14_linear_813_8839" x1="39.9118" y1="96.7138" x2="59.4176" y2="108.471" gradientUnits="userSpaceOnUse">
<stop stopColor="#4088E4"/>
<stop offset="1" stopColor="#1E20C3"/>
</linearGradient>
<linearGradient id="paint15_linear_813_8839" x1="42.1557" y1="83.9108" x2="50.1906" y2="90.4232" gradientUnits="userSpaceOnUse">
<stop offset="0.174605" stopColor="#609FDF"/>
<stop offset="0.34375" stopColor="#288CF2" stopOpacity="0.17"/>
<stop offset="0.517352" stopColor="#007EFF" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_813_8839" x1="36.5344" y1="60.521" x2="97.8913" y2="93.524" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.34375" stopColor="white"/>
<stop offset="0.588542" stopColor="white" stopOpacity="0"/>
<stop offset="0.791667" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_813_8839" x1="34.3448" y1="124.574" x2="50.6442" y2="115.287" gradientUnits="userSpaceOnUse">
<stop stopColor="#49A2E8"/>
<stop offset="0.390625" stopColor="#3F54DF"/>
<stop offset="0.942708" stopColor="#5825D3"/>
</linearGradient>
<linearGradient id="paint18_linear_813_8839" x1="36.6651" y1="120.237" x2="46.6112" y2="117.712" gradientUnits="userSpaceOnUse">
<stop stopColor="#5EB9F2"/>
<stop offset="1" stopColor="#7B40E7"/>
</linearGradient>
<linearGradient id="paint19_linear_813_8839" x1="44.428" y1="113.352" x2="40.9399" y2="116.466" gradientUnits="userSpaceOnUse">
<stop stopColor="#AE69EE"/>
<stop offset="1" stopColor="#7EBBF5"/>
</linearGradient>
</defs>
</svg>;

const proActive = <svg width="135" height="158" viewBox="0 0 135 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.6493 53.2362C45.4333 54.4651 46.3283 55.7196 47.3399 56.9712L38.5417 72.1996C38.4369 72.0214 38.3344 71.8423 38.2347 71.6626C37.4782 70.2994 37.0014 69.1129 36.7976 68.1688C36.6573 67.5187 36.6801 67.1507 36.7133 66.9723L44.6493 53.2362Z" stroke="url(#paint0_linear_813_8839)" strokeOpacity="0.3" strokeWidth="11" strokeMiterlimit="2.2042"/>
<path d="M110.99 71.5982C104.593 74.9518 94.3886 72.9409 88.6045 70.7502C84.3822 80.0506 76.7158 90.4978 73.5663 94.2891C79.9673 99.944 91.5775 101.962 96.3451 96.9469L110.99 71.5982Z" fill="#61A0FF" fillOpacity="0.4"/>
<path d="M81.6073 92.863C84.6303 88.726 88.4083 83.094 91.4447 77.4C93.8128 78.0104 96.4257 78.4988 99.0867 78.7322C99.5366 78.7717 99.9951 78.8045 100.46 78.8293L92.0198 93.4389C91.1806 94.0105 89.3564 94.5835 86.3092 94.1692C84.7226 93.9535 83.1099 93.4986 81.6073 92.863Z" stroke="url(#paint1_linear_813_8839)" strokeOpacity="0.3" strokeWidth="11" strokeMiterlimit="2.2042"/>
<g filter="url(#filter0_d_813_8839)">
<path d="M76.4276 33.0696C80.4898 29.9336 86.044 29.5623 90.4875 32.1295C94.9311 34.6968 97.3836 39.694 96.6959 44.7795L96.2717 47.9162L93.4124 58.7092C89.6676 72.8443 82.2249 85.7266 71.8498 96.031L56.0058 111.767L38.7188 101.779L44.4374 80.1936C48.1822 66.0585 55.625 53.1762 66.0001 42.8717L73.922 35.0037L76.4276 33.0696Z" fill="url(#paint2_radial_813_8839)"/>
<path d="M76.4276 33.0696C80.4898 29.9336 86.044 29.5623 90.4875 32.1295C94.9311 34.6968 97.3836 39.694 96.6959 44.7795L96.2717 47.9162L93.4124 58.7092C89.6676 72.8443 82.2249 85.7266 71.8498 96.031L56.0058 111.767L38.7188 101.779L44.4374 80.1936C48.1822 66.0585 55.625 53.1762 66.0001 42.8717L73.922 35.0037L76.4276 33.0696Z" fill="url(#paint3_radial_813_8839)" fillOpacity="0.2"/>
<path d="M77.0386 33.8611C80.7798 30.9731 85.895 30.6311 89.9873 32.9954C94.0796 35.3597 96.3383 39.9619 95.7049 44.6455L95.2891 47.7204L92.4457 58.4531C88.7465 72.4164 81.3942 85.1423 71.1451 95.3215L55.8438 110.519L39.8813 101.296L45.4041 80.4497C49.1034 66.4863 56.4557 53.7605 66.7048 43.5813L74.5825 35.7572L77.0386 33.8611Z" stroke="url(#paint4_linear_813_8839)" strokeWidth="2"/>
</g>
<g filter="url(#filter1_f_813_8839)">
<path d="M93.292 33.7498L90.5773 44.6263L86.9554 54.9786V54.9786C82.1687 68.6599 74.8543 81.3201 65.3927 92.3005L51.074 108.918" stroke="url(#paint5_linear_813_8839)" strokeWidth="3"/>
</g>
<path d="M92.1079 29.3249C95.3675 31.2082 96.5761 38.5245 96.5761 38.5245C96.5761 38.5245 92.6144 35.0611 89.7499 33.4061C86.8855 31.7512 81.9062 30.049 81.9062 30.049C81.9062 30.049 88.8482 27.4416 92.1079 29.3249Z" fill="url(#paint6_linear_813_8839)"/>
<g filter="url(#filter2_f_813_8839)">
<path d="M63.0708 46.215C71.4785 47.0022 89.2522 50.8624 93.0852 60.006" stroke="#D8E2F4" strokeWidth="2"/>
</g>
<g filter="url(#filter3_d_813_8839)">
<g filter="url(#filter4_i_813_8839)">
<ellipse cx="86.0885" cy="56.4228" rx="7.10078" ry="11.9592" transform="rotate(19.9001 86.0885 56.4228)" fill="url(#paint7_linear_813_8839)"/>
</g>
<ellipse cx="86.8733" cy="56.5788" rx="5.85503" ry="9.84144" transform="rotate(19.9001 86.8733 56.5788)" fill="url(#paint8_linear_813_8839)"/>
<g filter="url(#filter5_f_813_8839)">
<rect x="85.5586" y="47.1353" width="2.6574" height="7.22536" transform="rotate(1.78129 85.5586 47.1353)" fill="url(#paint9_linear_813_8839)" fillOpacity="0.54"/>
</g>
<g filter="url(#filter6_f_813_8839)">
<rect x="89.2627" y="45.7048" width="1.38936" height="7.22536" transform="rotate(1.78129 89.2627 45.7048)" fill="url(#paint10_linear_813_8839)" fillOpacity="0.54"/>
</g>
</g>
<circle cx="65.8419" cy="48.3914" r="0.498301" transform="rotate(30.0171 65.8419 48.3914)" fill="#D5E2F5"/>
<circle cx="69.7012" cy="49.1822" r="0.498301" transform="rotate(30.0171 69.7012 49.1822)" fill="#D5E2F5"/>
<circle cx="73.5606" cy="49.9732" r="0.498301" transform="rotate(30.0171 73.5606 49.9732)" fill="#D5E2F5"/>
<circle cx="77.295" cy="50.9801" r="0.498301" transform="rotate(30.0171 77.295 50.9801)" fill="#D5E2F5"/>
<g filter="url(#filter7_d_813_8839)">
<ellipse cx="66.5213" cy="90.4249" rx="2.61608" ry="8.59569" transform="rotate(38.9984 66.5213 90.4249)" fill="url(#paint11_linear_813_8839)"/>
<path d="M68.1659 91.7566C66.6943 93.5739 65.1496 95.0221 63.8539 95.8985C63.2034 96.3385 62.6399 96.6178 62.1969 96.7387C61.7399 96.8634 61.5216 96.7934 61.4267 96.7166C61.3318 96.6398 61.218 96.4408 61.245 95.9678C61.2711 95.5094 61.427 94.9002 61.7221 94.1724C62.3099 92.7227 63.4052 90.9106 64.8768 89.0933C66.3484 87.2759 67.8931 85.8277 69.1887 84.9513C69.8393 84.5113 70.4028 84.2321 70.8457 84.1112C71.3028 83.9865 71.521 84.0564 71.6159 84.1333C71.7108 84.2101 71.8246 84.4091 71.7977 84.882C71.7716 85.3405 71.6156 85.9497 71.3205 86.6775C70.7328 88.1271 69.6374 89.9392 68.1659 91.7566Z" stroke="url(#paint12_linear_813_8839)"/>
</g>
<path d="M79.3165 101.636L79.2855 87.3783C79.2855 87.3783 76.4767 91.1506 73.7534 93.9654C71.2534 96.5494 65.7428 101.854 65.7428 101.854L69.4541 108.44C71.2448 111.617 75.8282 111.594 77.5866 108.399C78.7264 106.327 79.3216 104.001 79.3165 101.636Z" fill="url(#paint13_linear_813_8839)"/>
<path d="M56.5961 110.974L61.8141 105.915C59.8968 102.236 57.0287 99.4095 52.7556 96.9407C48.4825 94.4719 44.9251 93.5859 40.7801 93.7625L38.6638 100.728C38.6842 100.692 38.703 100.659 38.72 100.629C39.6837 98.9611 44.2026 100.344 49.141 103.197C54.0794 106.05 57.5673 109.293 56.6035 110.961C56.6011 110.966 56.5986 110.97 56.5961 110.974Z" fill="url(#paint14_linear_813_8839)"/>
<path d="M42.938 93.8582L41.4893 100.071" stroke="#73B4F4" strokeWidth="3"/>
<path d="M47.125 94.5505L44.9161 100.756" stroke="#77A6F7" strokeWidth="3"/>
<path d="M51.3867 96.4373L48.2607 102.113" stroke="#7D99F6" strokeWidth="3"/>
<path d="M55.2841 98.6891L51.3457 104.183" stroke="#747CEF" strokeWidth="3"/>
<path d="M58.454 101.672L54.1293 106.51" stroke="#5049E3" strokeWidth="3"/>
<path d="M61.25 105.301L56.1952 109.286" stroke="#4433DA" strokeWidth="3"/>
<g filter="url(#filter8_i_813_8839)">
<path d="M56.6034 110.961C55.6397 112.629 51.1043 111.237 46.1659 108.384C41.2275 105.531 37.7561 102.297 38.7198 100.629C39.6835 98.961 44.2812 100.245 49.2196 103.098C54.158 105.952 57.5671 109.293 56.6034 110.961Z" fill="#262D79"/>
</g>
<g filter="url(#filter9_ii_813_8839)">
<path d="M34.2818 87.2545L42.5729 87.1732C42.5729 87.1732 46.5784 82.345 48.0522 78.6852C49.512 75.0604 48.4068 69.1068 48.4068 69.1068L36.1556 75.3381C33.7795 76.5466 31.805 78.4184 30.4714 80.7267C28.7859 83.644 30.9127 87.2876 34.2818 87.2545Z" fill="#5445E8"/>
</g>
<path d="M42.0384 92.3314C41.0125 92.3142 41.9672 88.8854 42.5728 87.1731C44.1884 85.3729 46.8569 80.92 47.9276 78.9009C47.6016 82.4533 43.3208 92.353 42.0384 92.3314Z" fill="url(#paint15_linear_813_8839)"/>
<path d="M96.5155 96.9014C100.646 89.7527 90.2785 76.0334 73.3597 66.2586C65.8391 61.9136 58.2899 59.0794 51.8608 57.8992C43.8266 56.4244 37.5415 57.5328 35.2472 61.5038" stroke="url(#paint16_linear_813_8839)" strokeWidth="4"/>
<g filter="url(#filter10_f_813_8839)">
<circle cx="70.6006" cy="64.8083" r="1.37033" transform="rotate(30.0171 70.6006 64.8083)" fill="white"/>
</g>
<path d="M46.9793 122.378C46.1056 123.89 43.6484 125.278 41.2228 126.343C38.1262 127.703 34.7073 125.727 34.3386 122.365C34.0499 119.732 34.0246 116.91 34.8983 115.398C36.8257 112.062 41.0926 110.92 44.4287 112.847C47.7648 114.775 48.9068 119.042 46.9793 122.378Z" fill="url(#paint17_linear_813_8839)"/>
<path d="M45.6341 119.228C45.3764 119.674 44.9449 120.117 44.4145 120.542C41.4965 122.88 37.2014 120.399 37.77 116.703C37.8733 116.031 38.0415 115.436 38.2992 114.99C39.6589 112.636 42.4032 111.677 44.4287 112.847C46.4542 114.018 46.9939 116.874 45.6341 119.228Z" fill="url(#paint18_linear_813_8839)"/>
<g filter="url(#filter11_d_813_8839)">
<circle cx="43.0577" cy="115.22" r="2.74065" transform="rotate(30.0171 43.0577 115.22)" fill="url(#paint19_linear_813_8839)"/>
</g>
<defs>
<filter id="filter0_d_813_8839" x="0.71875" y="0.432617" width="134.092" height="157.334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="19"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.5 0 0 0 0 1 0 0 0 0.23 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter1_f_813_8839" x="47.9375" y="31.3865" width="48.8101" height="80.5103" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter2_f_813_8839" x="60.9775" y="43.2195" width="35.0298" height="19.1731" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter3_d_813_8839" x="74.2676" y="44.9185" width="29.6421" height="37.0085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="7"/>
<feGaussianBlur stdDeviation="3.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.439216 0 0 0 0 0.615686 0 0 0 0 0.866667 0 0 0 0.35 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter4_i_813_8839" x="78.2676" y="42.9185" width="15.6421" height="25.0085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-2"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0374306 0 0 0 0 0.206613 0 0 0 0 0.641667 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
</filter>
<filter id="filter5_f_813_8839" x="82.334" y="44.1353" width="8.88086" height="13.3044" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter6_f_813_8839" x="86.0381" y="42.7048" width="7.61328" height="13.2651" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter7_d_813_8839" x="56.7412" y="83.5432" width="25.5605" height="27.7634" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="3" dy="7"/>
<feGaussianBlur stdDeviation="3.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.292639 0 0 0 0 0.335042 0 0 0 0 0.716667 0 0 0 0.22 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<filter id="filter8_i_813_8839" x="38.5586" y="99.8875" width="18.208" height="15.792" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
</filter>
<filter id="filter9_ii_813_8839" x="21.8809" y="68.1067" width="26.9268" height="33.1479" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="15"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 0.470588 0 0 0 0 0.968627 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_813_8839"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8" dy="-1"/>
<feGaussianBlur stdDeviation="7"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.105882 0 0 0 0 0.788235 0 0 0 1 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_813_8839" result="effect2_innerShadow_813_8839"/>
</filter>
<filter id="filter10_f_813_8839" x="66.23" y="60.4377" width="8.74121" height="8.74121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_813_8839"/>
</filter>
<filter id="filter11_d_813_8839" x="29.3164" y="105.479" width="27.4824" height="27.4822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.422979 0 0 0 0 0.123438 0 0 0 0 0.658333 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_813_8839"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_813_8839" result="shape"/>
</filter>
<linearGradient id="paint0_linear_813_8839" x1="52.8555" y1="56.4266" x2="32.0737" y2="66.0007" gradientUnits="userSpaceOnUse">
<stop stopColor="#1DFFBB"/>
<stop offset="1" stopColor="#0F6AF2"/>
</linearGradient>
<linearGradient id="paint1_linear_813_8839" x1="104.78" y1="79.5868" x2="84.2543" y2="93.117" gradientUnits="userSpaceOnUse">
<stop stopColor="#06C43B"/>
<stop offset="1" stopColor="#0F6AF2"/>
</linearGradient>
<radialGradient id="paint2_radial_813_8839" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.6695 104.683) rotate(-69.7496) scale(77.1084 38.7771)">
<stop stopColor="#79A2DD"/>
<stop offset="0.703125" stopColor="white"/>
</radialGradient>
<radialGradient id="paint3_radial_813_8839" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.5383 101.39) rotate(-48.9457) scale(46.1999 23.2335)">
<stop stopColor="#A8C3E8" stopOpacity="0.47"/>
<stop offset="1" stopColor="#D1E0F3"/>
</radialGradient>
<linearGradient id="paint4_linear_813_8839" x1="71.7886" y1="35.8573" x2="64.0621" y2="101.027" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_813_8839" x1="97.2831" y1="36.0556" x2="54.1578" y2="110.699" gradientUnits="userSpaceOnUse">
<stop stopColor="#F9FBFE"/>
<stop offset="1" stopColor="#6491D1"/>
</linearGradient>
<linearGradient id="paint6_linear_813_8839" x1="96.931" y1="38.7296" x2="81.9062" y2="30.049" gradientUnits="userSpaceOnUse">
<stop stopColor="#4839D3"/>
<stop offset="1" stopColor="#469DE7"/>
</linearGradient>
<linearGradient id="paint7_linear_813_8839" x1="86.0885" y1="44.4636" x2="83.4724" y2="67.7592" gradientUnits="userSpaceOnUse">
<stop offset="0.489694" stopColor="#ECF4FE"/>
<stop offset="1" stopColor="#BCD7FA"/>
</linearGradient>
<linearGradient id="paint8_linear_813_8839" x1="84.2573" y1="48.1076" x2="88.3755" y2="64.4252" gradientUnits="userSpaceOnUse">
<stop offset="0.489694" stopColor="#59C3EF"/>
<stop offset="1" stopColor="#408FEC"/>
</linearGradient>
<linearGradient id="paint9_linear_813_8839" x1="86.8873" y1="47.1353" x2="86.8873" y2="54.3606" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_813_8839" x1="89.9574" y1="45.7048" x2="89.9574" y2="52.9302" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_813_8839" x1="64.2327" y1="85.9273" x2="68.6378" y2="91.7366" gradientUnits="userSpaceOnUse">
<stop stopColor="#59C7F1"/>
<stop offset="1" stopColor="#4394ED"/>
</linearGradient>
<linearGradient id="paint12_linear_813_8839" x1="70.0626" y1="83.8862" x2="62.4127" y2="96.7784" gradientUnits="userSpaceOnUse">
<stop stopColor="#68C2EA"/>
<stop offset="1" stopColor="#3386EB"/>
</linearGradient>
<linearGradient id="paint13_linear_813_8839" x1="70.5439" y1="96.2833" x2="78.0947" y2="107.12" gradientUnits="userSpaceOnUse">
<stop offset="0.364583" stopColor="#001DD7"/>
<stop offset="0.59375" stopColor="#4040D9"/>
</linearGradient>
<linearGradient id="paint14_linear_813_8839" x1="39.9118" y1="96.7138" x2="59.4176" y2="108.471" gradientUnits="userSpaceOnUse">
<stop stopColor="#4088E4"/>
<stop offset="1" stopColor="#1E20C3"/>
</linearGradient>
<linearGradient id="paint15_linear_813_8839" x1="42.1557" y1="83.9108" x2="50.1906" y2="90.4232" gradientUnits="userSpaceOnUse">
<stop offset="0.174605" stopColor="#609FDF"/>
<stop offset="0.34375" stopColor="#288CF2" stopOpacity="0.17"/>
<stop offset="0.517352" stopColor="#007EFF" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_813_8839" x1="36.5344" y1="60.521" x2="97.8913" y2="93.524" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0"/>
<stop offset="0.34375" stopColor="white"/>
<stop offset="0.588542" stopColor="white" stopOpacity="0"/>
<stop offset="0.791667" stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_813_8839" x1="34.3448" y1="124.574" x2="50.6442" y2="115.287" gradientUnits="userSpaceOnUse">
<stop stopColor="#49A2E8"/>
<stop offset="0.390625" stopColor="#3F54DF"/>
<stop offset="0.942708" stopColor="#5825D3"/>
</linearGradient>
<linearGradient id="paint18_linear_813_8839" x1="36.6651" y1="120.237" x2="46.6112" y2="117.712" gradientUnits="userSpaceOnUse">
<stop stopColor="#5EB9F2"/>
<stop offset="1" stopColor="#7B40E7"/>
</linearGradient>
<linearGradient id="paint19_linear_813_8839" x1="44.428" y1="113.352" x2="40.9399" y2="116.466" gradientUnits="userSpaceOnUse">
<stop stopColor="#AE69EE"/>
<stop offset="1" stopColor="#7EBBF5"/>
</linearGradient>
</defs>
</svg>;

export const plansIcons = {
  free,
  medium,
  pro
}