import "./Tabs.css";

import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { AuthStore } from "features/Auth";
import { pagePaths } from "shared/config/pagePaths";

import { ITabMap } from "./types/ITabMap";
import { TabItem } from "./TabItem";
import { makeFromObjectMap } from "shared/lib";

export const Tabs = observer(() => {
  const { isAuth } = AuthStore.getShared();
  const navigate = useNavigate();
  const tabMap: ITabMap = {
    plans: {
      name: "Тарифы",
      action: () => navigate(pagePaths.TARIFFS_ROUTE),
      isActive: isAuth,
      path: pagePaths.TARIFFS_ROUTE
    },
    profile: {
      name: "Профиль",
      action: () => 1,
      isActive: false,
      path: pagePaths.PROFILE_ROUTE
    },
    playlist: {
      name: "Мой плейлист",
      action: () => 1,
      isActive: false,
      path: pagePaths.PLAYLIST_ROUTE
    },
    support: {
        name: "Поддержка",
        action: () => 1,
        isActive: false,
        path: pagePaths.SUPPORT_ROUTE
    }
  };
  const tabConfig = makeFromObjectMap(tabMap);

  return (
    <ul className="tabs">
      {
        tabConfig.map(({ key, item }) => <TabItem
          key={key}
          name={item.name}
          path={item.path}
          action={item.action}
          isActive={item.isActive}
        />
      )}
    </ul>
  );
});