import { ISongFavoriteResponseConfig } from "../types/ISongFavoriteResponseConfig";
import { SongModel } from "./SongModel";

export class SongFavoriteModel extends SongModel {
    songId: string = "";

    length: string = "";

    lengthText: string = "";

    path: string = "";

    mTime: number | null = null;

    uniqueId: string = "";

    storageLocationId: number;

    likesCount: number;

    constructor(backendData: ISongFavoriteResponseConfig) {
        super(backendData);

        this.songId = backendData.song_id;
        this.length = backendData.length;
        this.lengthText = backendData.length_text;
        this.path = backendData.path;
        this.mTime = backendData.mtime;
        this.uniqueId = backendData.unique_id;
        this.storageLocationId = backendData.storage_location_id;
        this.likesCount = backendData.count;
    }
}