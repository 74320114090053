export const pagePaths = {
    HOME_ROUTE:                     "/",
    PAGE_NOT_FOUND_ROUTE:           "/page-not-found",
    PROFILE_ROUTE:                  "/profile",
    PLAYLIST_ROUTE:                 "/playlist",
    ACCOUNT_ROUTE:                  "/account",
    PRICES_ROUTE:                   "/profile/prices",
    TARIFFS_ROUTE:                  "/tariffs",
    DJ_SETTING_ROUTE:               "/profile/dj-setting",
    SUPPORT_ROUTE:                  "/profile/support",

    /* Станции */
    station:                "/station",
    stationName:            "/station/:name",
    dnb:                    "/station/dnb",
    deep:                   "/station/deep",
    house:                  "/station/house",
    personal:               "/station/:stationId",

    /* Восстановление пароля */
    PASSWORD_RESET:                 "/password-reset",

    /* Верификация аккаунта */
    VERIFY: "/verify",

    UNKNOWN_ROUTE:                  "*",
};