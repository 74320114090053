import { action, makeObservable, observable } from "mobx";

export class ModalActionStore {
    isActiveModal: boolean;
    private static shared: ModalActionStore | null;

    static getShared(): ModalActionStore {
        if (this.shared) {
            return this.shared;
        }

        this.shared = new ModalActionStore();
        return this.shared;
    }

    constructor() {
        this.isActiveModal = false;

        makeObservable(this, {
            isActiveModal: observable,
            openForm: action,
            closeForm: action
        })
    }

    openForm() {
        this.isActiveModal = true;
    }

    closeForm() {
        this.isActiveModal = false;
    }
}