import { Fragment } from "react";
import { useScreenMode } from "app";
import { observer } from "mobx-react-lite";
import { SongFavoriteModel } from "entities/Song";
import { FavouriteModel } from "entities/Favourite";
import { useStoreFactory } from "shared/hook/useStoreFactory";

import { SidebarBtn } from "./ui/SidebarBtn/SidebarBtn";
import { Sidebar } from "./Sidebar";
import { SidebarStore } from "./store/SidebarStore";

type Props = {
  tracks: SongFavoriteModel[];
  isAuth: boolean;
  isCustomStation: boolean;
  historyTracks: SongFavoriteModel[];
  likedSongs: FavouriteModel[];
  onOpen(): void;
  onClose(): void;
  onNotAuth(): void;
};

/**
 * Композиция компонентов сайдбара: кнопки открытия и aside блока
 */

export const Layout = observer(({
  tracks,
  isAuth,
  isCustomStation,
  historyTracks,
  likedSongs,
  onOpen,
  onClose,
  onNotAuth
}: Props) => {
  const sidebarStore = useStoreFactory({
    createStore: () => new SidebarStore()
  });
  const isMobile = useScreenMode();
  const { sidebarOpened } = sidebarStore;
  const onSidebarAction = () => {
    if (isAuth) {
      sidebarStore.openSidebar();
      onOpen();
    } else {
      onNotAuth();
    }
  };

  return (
    <Fragment>
      <SidebarBtn
        action={onSidebarAction}
        isVisible={!isMobile}
        isAuth={isAuth}
      />
      <Sidebar
        sidebarState={sidebarOpened}
        onSidebarClose={() => {
          sidebarStore.closeSidebar();
          onClose();
        }}
        tracks={tracks}
        historyTracks={historyTracks}
        likedSongs={likedSongs}
        handleNonAuthAction={onNotAuth}
        isAuth={isAuth}
        isCustomStation={isCustomStation}
      />
    </Fragment>
  )
});