import { useTranslation } from "react-i18next";
import './LocalizationBtn.css';

export const LocalizationBtn = () => {
  const { i18n } = useTranslation();

  const setLocalization = (language: string) => {
    i18n.changeLanguage(language)
  }

  return (
    <div
      className="localization"
      onClick={() => { setLocalization(i18n.language === "ru" ? "en" : "ru") }}
    >{ i18n.language }</div>
  );
};