import "./VolumeBtn.css";

import {observer} from "mobx-react-lite";
import {RmdIcon} from "shared/ui/RmdIcon";

import {playerIcons} from "../../icons";

type Props = {
  mute: boolean;
  setMute: () => void;
};

export const VolumeBtn = observer(({mute, setMute}: Props) => (
  <span
    onClick={setMute}
    className="volume-btn"
  >
    <RmdIcon
      customCollection={playerIcons}
      type={mute ? "volumeOffIcon" : "volumeOnIcon"}
    />
  </span>
));
