import cn from "classnames";
import "./Button.css";

type ButtonType = "primary" | "secondary" | "mobile";
type ButtonSize = "large" | "medium" | "small";

type Props = {
    type: ButtonType;
    action?: (evt: any) => void;
    actionType?: "submit" | "button";
    text: string;
    size?: ButtonSize;
};

export const Button = ({ type, action, actionType, text, size }: Props) => {
    const className = cn(
        `button type_${type}`,
        `size_${size ? size : "medium"}`
    );

    return (
        <button
            onClick={action}
            className={className}
            type={actionType ? actionType : "button"}
        >
            {text}
        </button>
    );
};