import { IStationModelConfig } from "../types";
import { SITE_URL } from "../../../shared/config/backendPaths";

export class StationModel {
    public readonly id: number | null = null ;

    public readonly title: string = "";

    public readonly name: string = "";

    public readonly audioUrl: string = "";

    public readonly icon: string = "";

    public readonly position: number = 0;

    public readonly isAvailable: boolean = false;

    public readonly shortTitle: string = "";

    public readonly wssCode: string | null = "";

    public isCustom = false;

    constructor(backendData: IStationModelConfig) {
        this.id = backendData.id;
        this.title = backendData.title;
        this.name = backendData.icon;
        this.audioUrl = SITE_URL + backendData.audio_url;
        this.icon = backendData.icon;
        this.position = backendData.position;
        this.isAvailable = backendData.is_available;
        this.shortTitle = backendData.short_title;
        this.wssCode = backendData.wss_code;
    }
}