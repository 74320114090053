import axios from "axios";
import { action, makeObservable, observable, reaction, toJS } from "mobx";

import { BACKEND_URL, LIKE_PATH, TRACKS_PATH } from "shared/config/backendPaths";
import { EventEmitter } from "shared/lib/EventEmitter";
import { authToken } from "shared/config/LC_Entities";

import { FavouriteModelConfig } from "../types/FavouriteModelConfig";
import { FavouriteModel } from "../FavouriteModel";

/**
 * @FavouriteStore - стор, содержащий избранное: лайкнутые треки
 */

type EntityListEvents = {
  "fetchLikedSongs": () => void;
  "songHasBeenLiked": (data: FavouriteModelConfig[]) => void;
};

export class FavouriteStore extends EventEmitter<EntityListEvents> {
  private static shared: FavouriteStore | null;

  likedSongs: FavouriteModel[] = [];

  constructor() {
    super();

    makeObservable(this, {
      likedSongs: observable,
      setLikedSong: action
    });

    this.on("songHasBeenLiked", (likes) => {
      if (likes) {
        const likedSongs = likes.map((item) => new FavouriteModel(item));
        this.setLikedSong(likedSongs);
      }
    });

    this.on("fetchLikedSongs", () => this.getLiked());
  }

  setLikedSong(items: FavouriteModel[]) {
    if (items) {
      this.likedSongs = [];
    }

    this.likedSongs = items;
  }

  async getLiked() {
    const token = localStorage.getItem(authToken);
    if (!token) return;

    const result = await axios.get(BACKEND_URL + TRACKS_PATH + LIKE_PATH, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (result && result.data) {
      const likedSongs: FavouriteModel[] = result.data.map((item: FavouriteModelConfig) => new FavouriteModel(item));
      this.setLikedSong(likedSongs);
    }
  }

  static getShared(): FavouriteStore {
    if (this.shared) {
      return this.shared;
    }
    this.shared = new FavouriteStore();
    return this.shared;
  }
}