import "./TrackDetails.css";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { Fragment } from "react";

type Props = {
  title?: string;
};

export const TrackDetails = observer(({ title = "" }: Props) => {
  const location = useLocation();
  const allowToShow = location.pathname !== "/";

  if (!allowToShow) {
    return <Fragment />;
  }

  return (
    <p className="player-track-details">
      {title}
    </p>
  )
});
