/**
 * @FavouriteModel – сущность, которая хранит в себе список
 * id-лайкнутого трека, и id пользователя
 */
import { FavouriteModelConfig } from "./types/FavouriteModelConfig";

export class FavouriteModel {
    id: number;
    userId: number;
    songId: string;
    stationId: number;

    constructor(config: FavouriteModelConfig) {
        this.id = config.id;
        this.userId = config.user_id;
        this.songId = config.song_id;
        this.stationId = config.station_id;
    }
}