import { INotificationConfig } from "./types/INotificationConfig";
import { NotificationStatus } from "./types/NotificationStatus";

export class NotificationModel {
    status: NotificationStatus = 'default';

    title: string = '';

    body: string | null = null;

    id: string = "";

    constructor({ status, title, body, id }: INotificationConfig) {
        this.status = status;
        this.title = title;
        this.id = id;
        if (body) this.body = body;
    }

    static init({ status, title, body, id }: INotificationConfig) {
        return new NotificationModel({
            status,
            title,
            body,
            id
        });
    }
}