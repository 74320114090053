import { IMediaModelConfig, MediaModel } from "../MediaModel";
import { toggleLike } from "features/Like";

export interface INowPlayingModelConfig extends IMediaModelConfig {
  duration: number;
  elapsed: number;
  remaining: number;
}

export class NowPlayingModel extends MediaModel {
  duration: number = 0;

  elapsed: number = 0;

  remaining: number = 0;

  constructor(config: INowPlayingModelConfig) {
    super(config);

    this.duration = config.duration;
    this.elapsed = config.elapsed;
    this.remaining = config.remaining;
  }

  onToggleLike(stationId?: number) {
    if (!this.song?.id) return;
    return toggleLike({ songId: this.song.id, stationId });
  }
}





