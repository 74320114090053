import { EventEmitter as NativeEventEmitter } from 'events';

export type EventMap = {
    [key: string | symbol]: (...args: any[]) => void;
};

export interface EventEmitter<TSuperEvents extends EventMap = {}, TEvents extends EventMap = {}> {
    on<U extends keyof (TSuperEvents & TEvents)>(
        eventName: U, listener: (TSuperEvents & TEvents)[U]
    ): this;

    off<U extends keyof (TSuperEvents & TEvents)>(
        eventName: U, listener: (TSuperEvents & TEvents)[U]
    ): this;

    emit<U extends keyof (TSuperEvents & TEvents)>(
        eventName: U, ...args: Parameters<TSuperEvents[U]> | Parameters<TEvents[U]>
    ): boolean;
}

export class EventEmitter
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    <TSuperEvents extends EventMap = {}, TEvents extends EventMap ={}>
    extends NativeEventEmitter {}