//Основной URL

export const BACKEND_URL            = `https://${process.env.REACT_APP_BACKEND_DOMAIN}/api`;
export const SITE_URL            = `https://${process.env.REACT_APP_FRONT_DOMAIN}/`;
export const REGISTER_PATH          = "/register";
export const LOGIN_PATH             = "/login";
export const LOGOUT_PATH            = "/logout";
export const USER_PATH              = "/user";
export const MAIN_PATH              = "/main";
export const STATION_LIST_PATH      = `${MAIN_PATH}/stations`;
export const STATION_PATH = "/stations";
export const STATION_TOP_PATH = "/top";
export const ADMIN_SOCKET_URL = `wss://${process.env.REACT_APP_ADMIN_DOMAIN}/api/live/nowplaying/websocket`;
export const ADMIN_STATION_URL = `https://${process.env.REACT_APP_ADMIN_DOMAIN}/api`;
export const NOW_PLAYING = "/nowplaying";
export const MY_STATION = "/my-station";
export const TRACKS_PATH = "/tracks";
export const LIKE_PATH = "/like";
export const DOWNLOAD_PATH = "/download";
export const SEO_PATH = "/seo";
export const PASS_RECOVER = `${BACKEND_URL}/password/recovery`;
export const PASSWORD_CONFIRM = `${BACKEND_URL}/password/verify`;
export const ACCOUNT_VERIFY = `${BACKEND_URL}/account/verify`;
