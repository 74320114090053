import { observer } from "mobx-react-lite";
import cn from "classnames";
import "./StringInput.css";

type InputType = 'email' | 'password' | 'text';

type Props = {
    type: InputType;
    name: string;
    placeholder: string;
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    minLength: number;
    maxLength: number;
    hasError?: boolean;
    hasSuccess?: boolean;
};

export const StringInput = observer(({
    type, name, placeholder, onChange, minLength, maxLength, hasError, hasSuccess
}: Props) => {
    return (
        <input
            className={cn(
        "string-input", { "string-input_error": hasError }, {"string-input_success": hasSuccess}
            )}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            minLength={minLength}
            maxLength={maxLength}
            autoComplete="new-password"
        />
    );
});