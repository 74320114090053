import "./VolumeSlider.css";

import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";

type Props = {
  volume: string;
  setVolume: (volume: string) => void;
  isVertical?: boolean;
};

export const VolumeSlider = observer(({
  volume,
  setVolume,
  isVertical = false
}: Props) => {
  useEffect(() => {
    setVolume(volume);
  }, [volume]);

  return (
    <div className={cn(
        "progress-volume",
        { "progress-volume_mobile": isVertical })
      }
    >
      <div className="progress-done" />
      <input
        type="range"
        value={volume}
        min={0}
        max={1}
        onChange={(e) => setVolume(e.target.value)}
        step={0.01}
      />
    </div>
  );
});
