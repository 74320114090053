import "./HeaderAccount.css";

import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AuthProcess } from "app";
import { RegisterForm } from "features/Register";
import { AuthForm  } from "features/Auth";
import { ResetForm } from "features/Auth/ResetForm";
import { Modal } from "shared/ui/Modal";
import { ModalActionStore } from "features/PlayerControls/model/ModalActionStore";
import { pagePaths } from "shared/config/pagePaths";

import { HeaderContent } from "./ui/HeaderContent/HeaderContent";
import { IAuthConfig, INotAuthConfig } from "./types";


type Props = {
  authProcess: AuthProcess;
};

export const HeaderAccount = observer(({ authProcess }: Props) => {
  const { registerStore, authStore } = authProcess;
  const { isAuth, isLoading, currentUser } = authStore;
  const navigate = useNavigate();
  const actionStore = ModalActionStore.getShared();

  const authStateConfig: IAuthConfig = {
    action() {
      navigate(pagePaths.TARIFFS_ROUTE);
    },
    async onLogout() {
      await authStore.logout();
    },
    userEmail: currentUser?.email ?? "почта не указана",
  };

  const notAuthStateConfig: INotAuthConfig = {
    register: {
      action() {
        registerStore.openForm();
        actionStore.openForm();
      },
    },
    auth: {
      action() {
        authStore.openForm();
        actionStore.openForm();
      },
    }
  };

  return (
    <div className="header-account">
      <HeaderContent
        isLoading={isLoading}
        isAuth={isAuth}
        authConfig={authStateConfig}
        notAuthConfig={notAuthStateConfig}
      />
      <Modal
        isActive={registerStore.isFormActive}
        onClose={() => {
          registerStore.closeForm();
          actionStore.closeForm();
        }}
        children={
          <RegisterForm store={registerStore}/>
        }
      />
      <Modal
        isActive={authStore.isFormActive}
        onClose={() => {
          authStore.closeForm();
          actionStore.closeForm();
        }}
        children={authStore.needRecover
          ? (<ResetForm store={authStore}/>)
          : (<AuthForm store={authStore}/>)
        }
      />
    </div>
  );
});