import { FormEvent } from 'react';
import './Form.css';

type Props = {
    formTitle: string;
    onFormSubmit?: (evt: FormEvent) => void
    children: JSX.Element;
};

export const Form = ({ formTitle, onFormSubmit, children }: Props) => {
    return (
        <form className="form" onSubmit={onFormSubmit} autoComplete="off">
            <h3 className="form__title">
                { formTitle }
            </h3>
            {children}
        </form>
    );
};