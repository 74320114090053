import { useState, useEffect } from 'react';

type useStoreFactoryConfig<TStore> = {
    /** Фабрика для создания Store */
    createStore: () => TStore;
    /** Инициалищатор для Store */
    initStore?: (store: TStore) => void;
    /** Деструктор для Store */
    destroyStore?: (store: TStore) => void;
};

function isFn(x: any): x is Function {
    return typeof x === "function";
}

export function useStoreFactory<TStore> ({
    createStore,
    initStore,
    destroyStore
}: useStoreFactoryConfig<TStore>) {
    const [store] = useState(createStore);

    useEffect(() => {
        if (isFn(initStore)) {
            initStore(store);
        }

        return () => {
            if (isFn(destroyStore)) {
                destroyStore(store);
            //@ts-expect-error
            } else if (isFn(store.destroy)) {
                //@ts-expect-error
                store.destroy();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return store;
}
