import { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormTextLink } from "shared/ui/Form";
import { StringInput } from "shared/ui/StringInput";
import { Button } from "shared/ui/Button";
import { pagePaths } from "shared/config/pagePaths";

import { AuthStore } from "./model/AuthStore";
import {useTranslation} from "react-i18next";

export const NewPassForm = ({ store }: { store: AuthStore}) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<
        { password: string; passwordConfirmation: string; }
    >({
        password: "",
        passwordConfirmation: ""
    });

    const navigate = useNavigate();
    const location = useLocation();
    const passToken = location.search.slice(7);

    if (passToken) {
        localStorage.setItem("passToken", passToken);
    }

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => setUserData((prev) => ({
        ...prev,
        [evt.target.name]: evt.target.value
    }));

    const handleSubmitForm = async (evt: FormEvent) => {
        evt.preventDefault();

        /**
         * @TODO: доделать валидацию и проверку пароля
         */
        if (userData.password === userData.passwordConfirmation) {
            const result = await store.sendNewPassword({
                password: userData.password,
                password_confirmation: userData.passwordConfirmation
            });

            if (!result) {
              return;
            }

            const authResult = await store.login({
              email: result.user.email,
              password: userData.password,
            });

            /*  Если пароль успешно сброшен, редирект на HOME_ROUTE */
            if (authResult) {
                navigate(pagePaths.HOME_ROUTE);
            }
        }
    };

    return (
        <div className="reset-form">
            <Form
                formTitle={t("createNewPass")}
                onFormSubmit={handleSubmitForm}
            >
                <Fragment>
                    <StringInput
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <StringInput
                        type="password"
                        name="passwordConfirmation"
                        placeholder={t("repeatPassword")}
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <FormTextLink
                        actionMessage={t("passRemember")}
                        action={() => navigate(pagePaths.HOME_ROUTE)}
                    />
                    <Button
                        type="primary"
                        actionType="submit"
                        size="large"
                        text={t("submit")}
                    />
                </Fragment>
            </Form>
        </div>
    );
}