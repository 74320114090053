import './ModalOverlay.css';

type Props = {
    closeModal: () => void;
};

export const ModalOverlay = ({ closeModal }: Props) => {
    return (
        <div
            className="modal-overlay"
            onClick={closeModal}
        />
    );
};