import "./FormTextLink.css";

type Props = {
  message?: string;
  actionMessage: string;
  action: () => void;
};

export const FormTextLink = ({ message, actionMessage, action }: Props) => (
    <p className="form-text-link">
        {message} <span className="form-text-link__link" onClick={action}>{actionMessage}</span>
    </p>
)