import { action, makeObservable, observable } from "mobx";
import axios from "axios";
import {ADMIN_SOCKET_URL, ADMIN_STATION_URL, NOW_PLAYING} from "shared/config/backendPaths";
import { EventEmitter } from "shared/lib/EventEmitter";

import { SocketStatusType, StationCodeType } from "./types";

type EntityListEvents = {
    'manualFetch': (data: unknown) => any;
    'socketCreated': () => void;
    'socketConnected': () => void;
    'socketClosed': () => void;
    'socketDestroyed': () => void;
    'socketReInit': (wssCode: string) => void;
    'sendingData': () => void;
    'dataReceived': (data: unknown) => any;
};

export class AdminSocketStore extends EventEmitter<EntityListEvents> {
    socket: WebSocket | null = null;

    socketData: any = '';

    status: SocketStatusType = 'closed';

    query: string = '{ "subs": { "station:deep": {} }}';

    constructor() {
        super();

        this.on('socketCreated', () => {
            this.onOpen();
        });

        this.on('socketConnected', () => {
            this.onSubscribe();
        });

        this.on('socketClosed', () => {
            this.destroySocket();
        });

        this.on('socketReInit', (wssCode: string) => {
            this.setQuery(wssCode);
            this.reInit();
        });

        this.on('socketDestroyed', () => {
            this.createSocket();
        });

        this.on('sendingData', () => console.log('отправка запроса:', this.query));

        makeObservable(this, {
            socketData: observable,
            status: observable,
            socket: observable,
            setSocketData: action,
            setStatus: action,
            setSocket: action,
            onSend: action,
            setQuery: action
        });
    }

    /** Метод получения данных через json, до инициализации и ответа websocket */
    async getInitialData(stationCode: StationCodeType) {
        const res = await axios.get(ADMIN_STATION_URL + NOW_PLAYING + `/${stationCode}`);

        if (res && res.data) {
            this.emit("dataReceived", res.data);
        }
    }

    destroySocket() {
        if (this.socket) {
            this.setSocket(null);
            this.emit('socketDestroyed');
        }
    }

    createSocket() {
        if (this.socket === null) {
            this.setSocket(new WebSocket(ADMIN_SOCKET_URL));
            this.emit('socketCreated');
        }
    }

    onSubscribe() {
        if (!this.socket) return;
        if (this.status === 'opened') {
            this.onMessage();
            this.onSend();
        }
    }

    onOpen() {
        if (!this.socket) return;
        this.socket.onopen = () => {
            if (this.socket) {
                this.setStatus('opened');
                this.emit('socketConnected');
            }
        };

    }

    onClose() {
        if (!this.socket) return;
        this.socket.close();
        this.socket.onclose = () => this.emit('socketClosed');
    }

    onMessage() {
        if (!this.socket) return;
        let result = '';

        this.socket.onmessage = (event: MessageEvent) => {
            if (event.data) {
                result = JSON.parse(event.data);
                this.setSocketData(result);
                this.setStatus('message');
            }
        };
    }

    onSend() {
        if (!this.socket) return;
        if (!this.query) return;
        this.socket.send(this.query);
        this.setStatus("pending");
        this.emit('sendingData');
    }

    onError() {
        if (!this.socket) return;
        this.socket.onerror = (error: any) => {
            this.setStatus('error');
            console.log({ error: error });
        };
    }

    setSocket(value: WebSocket | null) {
        this.socket = value;
    }

    setQuery(stationName: string) {
        this.query = `{ "subs": { "station:${stationName}": {} }}`;
    }

    setSocketData(data: any) {
        if (!Reflect.has(data, 'pub')) return;
        this.socketData = data.pub.data.np;
        this.emit('dataReceived', this.socketData);
    }

    setStatus(status: SocketStatusType) {
        this.status = status;
    }

    reInit() {
        this.onClose();
        this.createSocket();
    }
}