import "./TimeLine.css";

import { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { secondsToMS } from "shared/lib/date";

type Props = {
  time: any;
  timeLineRef: any;
  isVisible: boolean;
};

export const TimeLine = observer(({ time, timeLineRef, isVisible }: Props) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  const setProgress = (second: number) => {
    if (second && timeLineRef.current) {
      if (time.duration)
        timeLineRef.current.style.width =
          (second / time.duration) * 100 + "%";
      else timeLineRef.current.style.width = "100%";
    }
  };

  useEffect(() => {
    setElapsedTime(time.elapsed);
    const interval = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    setProgress(elapsedTime);
  }, [elapsedTime]);

  if (!isVisible) {
    return <Fragment />
  }

  return (
    <div className="player-timeline">
      <div className="player-timeline__duration">
        <span>{secondsToMS(elapsedTime)}</span>
        <span>{secondsToMS(time.duration)}</span>
      </div>
      <div className="player-timeline__progress">
        <div className="player-timeline__done" ref={timeLineRef} />
      </div>
    </div>
  );
});
