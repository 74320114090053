export const dnb = <svg width="86" height="68" viewBox="0 0 86 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M25 0.25V66.25L0 56.25V10.25L25 0.25ZM22 4.6811V61.8189L3 54.2189V12.2811L22 4.6811Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29 0.25H57V64.75L54 67.75V3.25H32V67.75L29 64.75V0.25Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61 0.25V66.25L86 56.25V10.25L61 0.25ZM64 4.6811V61.8189L83 54.2189V12.2811L64 4.6811Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23 6.99902C15.0985 13.0282 10 22.5437 10 33.25C10 43.9563 15.0985 53.4718 23 59.501V55.611C16.8625 50.1178 13 42.135 13 33.25C13 24.365 16.8625 16.3822 23 10.889V6.99902ZM63 55.611C69.1375 50.1178 73 42.135 73 33.25C73 24.365 69.1375 16.3822 63 10.889V6.99902C70.9015 13.0282 76 22.5437 76 33.25C76 43.9563 70.9015 53.4718 63 59.501V55.611Z" fill="white"/>
</svg>;

export const dnbActive = <svg width="86" height="68" viewBox="0 0 86 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M25 0.25V66.25L0 56.25V10.25L25 0.25ZM22 4.6811V61.8189L3 54.2189V12.2811L22 4.6811Z" fill="url(#paint0_linear_360_730)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29 0.25H57V64.75L54 67.75V3.25H32V67.75L29 64.75V0.25Z" fill="url(#paint1_linear_360_730)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61 0.25V66.25L86 56.25V10.25L61 0.25ZM64 4.6811V61.8189L83 54.2189V12.2811L64 4.6811Z" fill="url(#paint2_linear_360_730)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23 6.99902C15.0985 13.0282 10 22.5437 10 33.25C10 43.9563 15.0985 53.4718 23 59.501V55.611C16.8625 50.1178 13 42.135 13 33.25C13 24.365 16.8625 16.3822 23 10.889V6.99902ZM63 55.611C69.1375 50.1178 73 42.135 73 33.25C73 24.365 69.1375 16.3822 63 10.889V6.99902C70.9015 13.0282 76 22.5437 76 33.25C76 43.9563 70.9015 53.4718 63 59.501V55.611Z" fill="url(#paint3_linear_360_730)"/>
<defs>
<linearGradient id="paint0_linear_360_730" x1="1.94408e-06" y1="67.75" x2="91.5056" y2="8.79545" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint1_linear_360_730" x1="1.94408e-06" y1="67.75" x2="91.5056" y2="8.79545" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint2_linear_360_730" x1="1.94408e-06" y1="67.75" x2="91.5056" y2="8.79545" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint3_linear_360_730" x1="1.94408e-06" y1="67.75" x2="91.5056" y2="8.79545" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
</defs>
</svg>;

const dnbInactive = <svg width="86" height="68" viewBox="0 0 86 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M25 0.25V66.25L0 56.25V10.25L25 0.25ZM22 4.6811V61.8189L3 54.2189V12.2811L22 4.6811Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29 0.25H57V64.75L54 67.75V3.25H32V67.75L29 64.75V0.25Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61 0.25V66.25L86 56.25V10.25L61 0.25ZM64 4.6811V61.8189L83 54.2189V12.2811L64 4.6811Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23 6.99902C15.0985 13.0282 10 22.5437 10 33.25C10 43.9563 15.0985 53.4718 23 59.501V55.611C16.8625 50.1178 13 42.135 13 33.25C13 24.365 16.8625 16.3822 23 10.889V6.99902ZM63 55.611C69.1375 50.1178 73 42.135 73 33.25C73 24.365 69.1375 16.3822 63 10.889V6.99902C70.9015 13.0282 76 22.5437 76 33.25C76 43.9563 70.9015 53.4718 63 59.501V55.611Z" fill="#7978AA"/>
</svg>;

const deep = <svg width="70" height="94" viewBox="0 0 70 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_360_5548)">
<path fillRule="evenodd" clipRule="evenodd" d="M66.2725 13.0001H3.5V75.7727H66.2725V13.0001ZM0.5 10.0001V78.7727H69.2725V10.0001H0.5Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M52.5 27.0001H17.5V62.0001H52.5V27.0001ZM14.5 24.0001V65.0001H55.5V24.0001H14.5Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.4395 28.0608L2.43945 14.0608L4.56077 11.9395L18.5608 25.9395L16.4395 28.0608Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M66.4395 78.0608L52.4395 64.0608L54.5608 61.9395L68.5608 75.9395L66.4395 78.0608Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.5605 64.0608L4.56053 78.0608L2.43921 75.9395L16.4392 61.9395L18.5605 64.0608Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.5605 14.0608L54.5605 28.0608L52.4392 25.9395L66.4392 11.9395L68.5605 14.0608Z" fill="#fff"/>
<path d="M61.5 39.0747H36.7139L52 1L38.4977 9.32493L33.2699 1.05737L18.3093 49.5001H39.7647L14.5 93.0001L61.5 39.0747Z" fill="#fff"/>
</g>
<defs>
<clipPath id="clip0_360_5548">
<rect width="69" height="93" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>;

const deepActive = <svg width="70" height="94" viewBox="0 0 70 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_97_8957)">
<path fillRule="evenodd" clipRule="evenodd" d="M66.2725 13H3.5V75.7726H66.2725V13ZM0.5 10V78.7726H69.2725V10H0.5Z" fill="url(#paint0_linear_97_8957)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M52.5 27H17.5V62H52.5V27ZM14.5 24V65H55.5V24H14.5Z" fill="url(#paint1_linear_97_8957)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.4395 28.0608L2.43945 14.0608L4.56077 11.9395L18.5608 25.9395L16.4395 28.0608Z" fill="url(#paint2_linear_97_8957)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M66.4395 78.0608L52.4395 64.0608L54.5608 61.9395L68.5608 75.9395L66.4395 78.0608Z" fill="url(#paint3_linear_97_8957)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.5607 64.0608L4.56077 78.0608L2.43945 75.9395L16.4394 61.9395L18.5607 64.0608Z" fill="url(#paint4_linear_97_8957)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.5605 14.0608L54.5605 28.0608L52.4392 25.9395L66.4392 11.9395L68.5605 14.0608Z" fill="url(#paint5_linear_97_8957)"/>
<path d="M61.5 39.0747H36.7139L52 1L38.4977 9.32493L33.2699 1.05737L18.3093 49.5001H39.7647L14.5 93.0001L61.5 39.0747Z" fill="url(#paint6_linear_97_8957)"/>
</g>
<defs>
<linearGradient id="paint0_linear_97_8957" x1="0.500002" y1="78.7726" x2="82.9627" y2="37.073" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint1_linear_97_8957" x1="14.5" y1="65" x2="63.6617" y2="40.14" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint2_linear_97_8957" x1="2.43945" y1="28.0608" x2="21.77" y2="18.2857" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint3_linear_97_8957" x1="52.4395" y1="78.0608" x2="71.7699" y2="68.2857" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint4_linear_97_8957" x1="2.43945" y1="78.0608" x2="21.7699" y2="68.2857" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint5_linear_97_8957" x1="52.4392" y1="28.0608" x2="71.7697" y2="18.2857" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint6_linear_97_8957" x1="14.5" y1="93.0001" x2="80.8396" y2="75.8622" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<clipPath id="clip0_97_8957">
<rect width="69" height="93" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>;

const deepInactive = <svg width="70" height="94" viewBox="0 0 70 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_434_5548)">
<path fillRule="evenodd" clipRule="evenodd" d="M66.2725 13.0001H3.5V75.7727H66.2725V13.0001ZM0.5 10.0001V78.7727H69.2725V10.0001H0.5Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M52.5 27.0001H17.5V62.0001H52.5V27.0001ZM14.5 24.0001V65.0001H55.5V24.0001H14.5Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.4395 28.0608L2.43945 14.0608L4.56077 11.9395L18.5608 25.9395L16.4395 28.0608Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M66.4395 78.0608L52.4395 64.0608L54.5608 61.9395L68.5608 75.9395L66.4395 78.0608Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.5605 64.0608L4.56053 78.0608L2.43921 75.9395L16.4392 61.9395L18.5605 64.0608Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.5605 14.0608L54.5605 28.0608L52.4392 25.9395L66.4392 11.9395L68.5605 14.0608Z" fill="#7978AA"/>
<path d="M61.5 39.0747H36.7139L52 1L38.4977 9.32493L33.2699 1.05737L18.3093 49.5001H39.7647L14.5 93.0001L61.5 39.0747Z" fill="#7978AA"/>
</g>
<defs>
<clipPath id="clip0_434_5548">
<rect width="69" height="93" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>;

const house = <svg width="74" height="82" viewBox="0 0 74 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.41739 23.7411L33.5949 41.9342V78.4502L2.59079 60.2125L2.41739 23.7411ZM71.595 23.6954L71.5947 60.2149L40.5947 78.4502L40.5949 41.9307L71.595 23.6954ZM68.0163 19.1436L37.0949 37.3326L6.14939 19.1292L36.738 2.62743L68.0163 19.1436Z" stroke="#fff" strokeWidth="3"/>
</svg>;

const houseActive = <svg width="74" height="82" viewBox="0 0 74 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.4175 23.7412L33.595 41.9343V78.4502L2.5909 60.2126L2.4175 23.7412ZM71.5952 23.6955L71.5948 60.215L40.5948 78.4503L40.595 41.9308L71.5952 23.6955ZM68.0164 19.1437L37.095 37.3327L6.1495 19.1293L36.7381 2.62752L68.0164 19.1437Z" stroke="url(#paint0_linear_97_9084)" strokeWidth="3"/>
<defs>
<linearGradient id="paint0_linear_97_9084" x1="0.905031" y1="81.0729" x2="90.9243" y2="40.0706" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
</defs>
</svg>;

const houseInactive = <svg width="74" height="82" viewBox="0 0 74 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.41739 23.7411L33.5949 41.9342V78.4502L2.59079 60.2125L2.41739 23.7411ZM71.595 23.6954L71.5947 60.2149L40.5947 78.4502L40.5949 41.9307L71.595 23.6954ZM68.0163 19.1436L37.0949 37.3326L6.14939 19.1292L36.738 2.62743L68.0163 19.1436Z" stroke="#7978AA" strokeWidth="3"/>
</svg>;

const personal = <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_360_737)">
<path fillRule="evenodd" clipRule="evenodd" d="M39.0002 41.8325C41.7482 36.5167 43.3001 30.4828 43.3001 24.0808C43.3001 17.6789 41.7482 11.645 39.0002 6.32922C36.2522 11.645 34.7003 17.6789 34.7003 24.0808C34.7003 30.4828 36.2522 36.5167 39.0002 41.8325ZM40.7543 44.8776C40.2055 45.83 39.6202 46.7586 39.0002 47.6617C38.3802 46.7586 37.7949 45.83 37.2461 44.8776C33.7184 38.7556 31.7003 31.6539 31.7003 24.0808C31.7003 16.5078 33.7184 9.40604 37.2461 3.28411C37.7949 2.33172 38.3802 1.40305 39.0002 0.5C39.6202 1.40305 40.2055 2.33172 40.7543 3.28411C44.282 9.40604 46.3001 16.5078 46.3001 24.0808C46.3001 31.6539 44.282 38.7556 40.7543 44.8776Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M39.0002 71.6704C41.7482 66.3546 43.3001 60.3207 43.3001 53.9187C43.3001 47.5168 41.7482 41.4829 39.0002 36.1671C36.2522 41.4829 34.7003 47.5168 34.7003 53.9187C34.7003 60.3207 36.2522 66.3546 39.0002 71.6704ZM40.7543 74.7155C40.2055 75.6679 39.6202 76.5965 39.0002 77.4996C38.3802 76.5965 37.7949 75.6679 37.2461 74.7155C33.7184 68.5935 31.7003 61.4918 31.7003 53.9187C31.7003 46.3457 33.7184 39.2439 37.2461 33.122C37.7949 32.1696 38.3802 31.2409 39.0002 30.3379C39.6202 31.2409 40.2055 32.1696 40.7543 33.122C44.282 39.2439 46.3001 46.3457 46.3001 53.9187C46.3001 61.4918 44.282 68.5935 40.7543 74.7155Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.1671 38.9996C41.4829 41.7477 47.5169 43.2996 53.919 43.2996C60.3211 43.2996 66.3551 41.7477 71.6709 38.9996C66.3551 36.2515 60.3211 34.6996 53.919 34.6996C47.5169 34.6996 41.4829 36.2515 36.1671 38.9996ZM33.122 40.7537C32.1697 40.2049 31.241 39.6196 30.338 38.9996C31.241 38.3796 32.1697 37.7943 33.122 37.2455C39.244 33.7177 46.3458 31.6996 53.919 31.6996C61.4921 31.6996 68.5939 33.7177 74.7159 37.2455C75.6683 37.7943 76.597 38.3796 77.5 38.9996C76.597 39.6196 75.6683 40.2049 74.7159 40.7537C68.5939 44.2815 61.4921 46.2996 53.919 46.2996C46.3458 46.2996 39.244 44.2815 33.122 40.7537Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.32921 38.9996C11.645 41.7477 17.679 43.2996 24.0811 43.2996C30.4832 43.2996 36.5172 41.7477 41.833 38.9996C36.5172 36.2515 30.4832 34.6996 24.0811 34.6996C17.679 34.6996 11.645 36.2515 6.32921 38.9996ZM3.28414 40.7537C2.33176 40.2049 1.4031 39.6196 0.500061 38.9996C1.4031 38.3796 2.33176 37.7943 3.28414 37.2455C9.40612 33.7177 16.5079 31.6996 24.0811 31.6996C31.6542 31.6996 38.756 33.7177 44.878 37.2455C45.8304 37.7943 46.7591 38.3796 47.6621 38.9996C46.7591 39.6196 45.8304 40.2049 44.878 40.7537C38.756 44.2815 31.6542 46.2996 24.0811 46.2996C16.5079 46.2996 9.40612 44.2815 3.28414 40.7537Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M41.0034 41.0023C39.1877 35.3004 36.0184 29.9364 31.4916 25.4096C26.9648 20.8828 21.601 17.7136 15.8992 15.8979C17.7148 21.5998 20.8841 26.9637 25.4109 31.4906C29.9377 36.0173 35.3016 39.1866 41.0034 41.0023ZM44.3969 41.9152C44.6823 42.9767 44.9251 44.0473 45.1253 45.1242C44.0483 44.924 42.9778 44.6812 41.9163 44.3958C35.0931 42.5614 28.6445 38.9668 23.2896 33.6119C17.9347 28.2569 14.34 21.8083 12.5056 14.985C12.2202 13.9235 11.9774 12.8529 11.7773 11.776C12.8542 11.9762 13.9248 12.2189 14.9863 12.5043C21.8095 14.3387 28.258 17.9334 33.6129 23.2883C38.9679 28.6432 42.5625 35.0919 44.3969 41.9152Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.0153 65.4947C64.0768 65.7801 65.1474 66.0229 66.2243 66.223C66.0241 65.1461 65.7813 64.0755 65.496 63.014C63.6615 56.1907 60.0669 49.7421 54.7119 44.3872C49.3569 39.0321 42.9081 35.4374 36.0846 33.603C35.0231 33.3177 33.9526 33.0749 32.8756 32.8747C33.0758 33.9517 33.3186 35.0223 33.604 36.0838C35.4384 42.907 39.033 49.3557 44.388 54.7106C49.743 60.0656 56.1918 63.6604 63.0153 65.4947ZM62.1024 62.1011C60.2867 56.3993 57.1174 51.0354 52.5906 46.5085C48.0637 41.9816 42.6996 38.8123 36.9975 36.9966C38.8132 42.6985 41.9825 48.0624 46.5093 52.5893C51.0363 57.1162 56.4003 60.2855 62.1024 62.1011Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.9972 41.0026C42.6992 39.1869 48.0633 36.0176 52.5902 31.4907C57.1171 26.9638 60.2864 21.5998 62.1021 15.8979C56.4 17.7135 51.036 20.8828 46.509 25.4098C41.9821 29.9367 38.8129 35.3006 36.9972 41.0026ZM36.0843 44.3961C35.0228 44.6815 33.9523 44.9243 32.8753 45.1244C33.0755 44.0475 33.3183 42.9769 33.6037 41.9154C35.438 35.0921 39.0327 28.6434 44.3877 23.2884C49.7427 17.9334 56.1915 14.3387 63.0149 12.5043C64.0764 12.219 65.147 11.9762 66.2239 11.776C66.0237 12.853 65.781 13.9235 65.4956 14.985C63.6612 21.8084 60.0665 28.257 54.7115 33.612C49.3565 38.967 42.9077 42.5617 36.0843 44.3961Z" fill="#fff"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8982 62.1013C21.6002 60.2857 26.9643 57.1164 31.4912 52.5894C36.0181 48.0626 39.1874 42.6986 41.0031 36.9966C35.301 38.8123 29.937 41.9816 25.41 46.5085C20.8831 51.0354 17.7138 56.3994 15.8982 62.1013ZM14.9853 65.4949C13.9238 65.7802 12.8533 66.023 11.7763 66.2232C11.9765 65.1462 12.2193 64.0757 12.5047 63.0142C14.339 56.1909 17.9337 49.7422 23.2887 44.3872C28.6437 39.0322 35.0925 35.4375 41.9159 33.6031C42.9774 33.3177 44.048 33.075 45.1249 32.8748C44.9247 33.9517 44.682 35.0223 44.3966 36.0838C42.5622 42.9071 38.9675 49.3558 33.6125 54.7108C28.2575 60.0658 21.8087 63.6605 14.9853 65.4949Z" fill="#fff"/>
</g>
<defs>
<clipPath id="clip0_360_737">
<rect width="78" height="77" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>;

const personalActive = <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_97_9044)">
<path fillRule="evenodd" clipRule="evenodd" d="M39.0001 41.8325C41.7481 36.5167 43.3 30.4828 43.3 24.0808C43.3 17.6789 41.7481 11.645 39.0001 6.32922C36.2521 11.645 34.7002 17.6789 34.7002 24.0808C34.7002 30.4828 36.2521 36.5167 39.0001 41.8325ZM40.7542 44.8776C40.2054 45.83 39.6201 46.7586 39.0001 47.6617C38.3801 46.7586 37.7948 45.83 37.246 44.8776C33.7183 38.7556 31.7002 31.6539 31.7002 24.0808C31.7002 16.5078 33.7183 9.40604 37.246 3.28411C37.7948 2.33172 38.3801 1.40305 39.0001 0.5C39.6201 1.40305 40.2054 2.33172 40.7542 3.28411C44.2819 9.40604 46.3 16.5078 46.3 24.0808C46.3 31.6539 44.2819 38.7556 40.7542 44.8776Z" fill="url(#paint0_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M39.0001 71.6704C41.7481 66.3546 43.3 60.3207 43.3 53.9187C43.3 47.5168 41.7481 41.4829 39.0001 36.1671C36.2521 41.4829 34.7002 47.5168 34.7002 53.9187C34.7002 60.3207 36.2521 66.3546 39.0001 71.6704ZM40.7542 74.7155C40.2054 75.6679 39.6201 76.5965 39.0001 77.4996C38.3801 76.5965 37.7948 75.6679 37.246 74.7155C33.7183 68.5935 31.7002 61.4918 31.7002 53.9187C31.7002 46.3457 33.7183 39.2439 37.246 33.122C37.7948 32.1696 38.3801 31.2409 39.0001 30.3379C39.6201 31.2409 40.2054 32.1696 40.7542 33.122C44.2819 39.2439 46.3 46.3457 46.3 53.9187C46.3 61.4918 44.2819 68.5935 40.7542 74.7155Z" fill="url(#paint1_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.167 38.9997C41.4828 41.7478 47.5168 43.2997 53.9189 43.2997C60.321 43.2997 66.355 41.7478 71.6708 38.9997C66.355 36.2516 60.321 34.6997 53.9189 34.6997C47.5168 34.6997 41.4828 36.2516 36.167 38.9997ZM33.1219 40.7538C32.1696 40.205 31.2409 39.6197 30.3379 38.9997C31.2409 38.3797 32.1696 37.7944 33.1219 37.2456C39.2439 33.7178 46.3457 31.6997 53.9189 31.6997C61.492 31.6997 68.5938 33.7178 74.7158 37.2456C75.6682 37.7944 76.5969 38.3797 77.4999 38.9997C76.5969 39.6197 75.6682 40.205 74.7158 40.7538C68.5938 44.2816 61.492 46.2997 53.9189 46.2997C46.3457 46.2997 39.2439 44.2816 33.1219 40.7538Z" fill="url(#paint2_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.32915 38.9997C11.6449 41.7478 17.6789 43.2997 24.081 43.2997C30.4831 43.2997 36.5171 41.7478 41.8329 38.9997C36.5171 36.2516 30.4831 34.6997 24.081 34.6997C17.6789 34.6997 11.6449 36.2516 6.32915 38.9997ZM3.28408 40.7538C2.3317 40.205 1.40304 39.6197 0.5 38.9997C1.40304 38.3797 2.3317 37.7944 3.28408 37.2456C9.40606 33.7178 16.5078 31.6997 24.081 31.6997C31.6541 31.6997 38.7559 33.7178 44.8779 37.2456C45.8303 37.7944 46.759 38.3797 47.662 38.9997C46.759 39.6197 45.8303 40.205 44.8779 40.7538C38.7559 44.2816 31.6541 46.2997 24.081 46.2997C16.5078 46.2997 9.40606 44.2816 3.28408 40.7538Z" fill="url(#paint3_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M41.0034 41.0022C39.1877 35.3003 36.0184 29.9363 31.4916 25.4095C26.9648 20.8827 21.601 17.7135 15.8992 15.8978C17.7148 21.5997 20.8841 26.9636 25.4109 31.4905C29.9377 36.0172 35.3016 39.1865 41.0034 41.0022ZM44.3969 41.9151C44.6823 42.9766 44.9251 44.0472 45.1253 45.1241C44.0483 44.9239 42.9778 44.6811 41.9163 44.3957C35.0931 42.5613 28.6445 38.9667 23.2896 33.6118C17.9347 28.2568 14.34 21.8082 12.5056 14.9849C12.2202 13.9234 11.9774 12.8528 11.7773 11.7759C12.8542 11.9761 13.9248 12.2188 14.9863 12.5042C21.8095 14.3386 28.258 17.9333 33.6129 23.2882C38.9679 28.6431 42.5625 35.0918 44.3969 41.9151Z" fill="url(#paint4_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.0152 65.4945C64.0767 65.7799 65.1473 66.0227 66.2242 66.2228C66.024 65.1459 65.7812 64.0753 65.4959 63.0138C63.6614 56.1905 60.0668 49.7419 54.7118 44.387C49.3568 39.0319 42.908 35.4372 36.0845 33.6028C35.023 33.3175 33.9525 33.0747 32.8755 32.8745C33.0757 33.9515 33.3185 35.0221 33.6039 36.0836C35.4383 42.9068 39.0329 49.3555 44.3879 54.7104C49.7429 60.0654 56.1917 63.6602 63.0152 65.4945ZM62.1023 62.1009C60.2866 56.3991 57.1173 51.0352 52.5905 46.5083C48.0636 41.9814 42.6995 38.8121 36.9974 36.9964C38.8131 42.6983 41.9824 48.0622 46.5092 52.5891C51.0362 57.116 56.4002 60.2853 62.1023 62.1009Z" fill="url(#paint5_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.9971 41.0025C42.6991 39.1868 48.0632 36.0175 52.5901 31.4906C57.117 26.9637 60.2863 21.5997 62.102 15.8978C56.3999 17.7134 51.0359 20.8827 46.5089 25.4097C41.982 29.9366 38.8128 35.3005 36.9971 41.0025ZM36.0842 44.396C35.0227 44.6814 33.9522 44.9242 32.8752 45.1243C33.0754 44.0474 33.3182 42.9768 33.6036 41.9153C35.4379 35.092 39.0326 28.6433 44.3876 23.2883C49.7426 17.9333 56.1914 14.3386 63.0148 12.5042C64.0763 12.2189 65.1469 11.9761 66.2238 11.7759C66.0236 12.8529 65.7809 13.9234 65.4955 14.9849C63.6611 21.8083 60.0664 28.2569 54.7114 33.6119C49.3564 38.9669 42.9076 42.5616 36.0842 44.396Z" fill="url(#paint6_linear_97_9044)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8983 62.1015C21.6003 60.2859 26.9644 57.1166 31.4913 52.5896C36.0182 48.0628 39.1875 42.6988 41.0032 36.9968C35.3011 38.8125 29.9371 41.9818 25.4101 46.5087C20.8832 51.0356 17.7139 56.3996 15.8983 62.1015ZM14.9854 65.4951C13.9239 65.7804 12.8534 66.0232 11.7764 66.2234C11.9766 65.1464 12.2194 64.0759 12.5048 63.0144C14.3391 56.1911 17.9338 49.7424 23.2888 44.3874C28.6438 39.0324 35.0926 35.4377 41.916 33.6033C42.9775 33.3179 44.0481 33.0752 45.125 32.875C44.9248 33.9519 44.6821 35.0225 44.3967 36.084C42.5623 42.9073 38.9676 49.356 33.6126 54.711C28.2576 60.066 21.8088 63.6607 14.9854 65.4951Z" fill="url(#paint7_linear_97_9044)"/>
</g>
<defs>
<linearGradient id="paint0_linear_97_9044" x1="31.7002" y1="47.6617" x2="53.157" y2="44.3028" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint1_linear_97_9044" x1="31.7002" y1="77.4996" x2="53.157" y2="74.1407" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint2_linear_97_9044" x1="30.3379" y1="46.2997" x2="49.6961" y2="14.6785" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint3_linear_97_9044" x1="0.500001" y1="46.2997" x2="19.8582" y2="14.6785" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint4_linear_97_9044" x1="11.7773" y1="45.1241" x2="51.7639" y2="24.9039" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint5_linear_97_9044" x1="32.8755" y1="66.2228" x2="72.8625" y2="46.002" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint6_linear_97_9044" x1="32.8752" y1="45.1243" x2="72.8623" y2="24.9036" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<linearGradient id="paint7_linear_97_9044" x1="11.7764" y1="66.2234" x2="51.7634" y2="46.0027" gradientUnits="userSpaceOnUse">
<stop stopColor="#801AB3"/>
<stop offset="1" stopColor="#4C4CFF"/>
</linearGradient>
<clipPath id="clip0_97_9044">
<rect width="78" height="77" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>;

const personalInactive = <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_434_737)">
<path fillRule="evenodd" clipRule="evenodd" d="M39.0002 41.8325C41.7482 36.5167 43.3001 30.4828 43.3001 24.0808C43.3001 17.6789 41.7482 11.645 39.0002 6.32922C36.2522 11.645 34.7003 17.6789 34.7003 24.0808C34.7003 30.4828 36.2522 36.5167 39.0002 41.8325ZM40.7543 44.8776C40.2055 45.83 39.6202 46.7586 39.0002 47.6617C38.3802 46.7586 37.7949 45.83 37.2461 44.8776C33.7184 38.7556 31.7003 31.6539 31.7003 24.0808C31.7003 16.5078 33.7184 9.40604 37.2461 3.28411C37.7949 2.33172 38.3802 1.40305 39.0002 0.5C39.6202 1.40305 40.2055 2.33172 40.7543 3.28411C44.282 9.40604 46.3001 16.5078 46.3001 24.0808C46.3001 31.6539 44.282 38.7556 40.7543 44.8776Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M39.0002 71.6704C41.7482 66.3546 43.3001 60.3207 43.3001 53.9187C43.3001 47.5168 41.7482 41.4829 39.0002 36.1671C36.2522 41.4829 34.7003 47.5168 34.7003 53.9187C34.7003 60.3207 36.2522 66.3546 39.0002 71.6704ZM40.7543 74.7155C40.2055 75.6679 39.6202 76.5965 39.0002 77.4996C38.3802 76.5965 37.7949 75.6679 37.2461 74.7155C33.7184 68.5935 31.7003 61.4918 31.7003 53.9187C31.7003 46.3457 33.7184 39.2439 37.2461 33.122C37.7949 32.1696 38.3802 31.2409 39.0002 30.3379C39.6202 31.2409 40.2055 32.1696 40.7543 33.122C44.282 39.2439 46.3001 46.3457 46.3001 53.9187C46.3001 61.4918 44.282 68.5935 40.7543 74.7155Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.1671 38.9996C41.4829 41.7477 47.5169 43.2996 53.919 43.2996C60.3211 43.2996 66.3551 41.7477 71.6709 38.9996C66.3551 36.2515 60.3211 34.6996 53.919 34.6996C47.5169 34.6996 41.4829 36.2515 36.1671 38.9996ZM33.122 40.7537C32.1697 40.2049 31.241 39.6196 30.338 38.9996C31.241 38.3796 32.1697 37.7943 33.122 37.2455C39.244 33.7177 46.3458 31.6996 53.919 31.6996C61.4921 31.6996 68.5939 33.7177 74.7159 37.2455C75.6683 37.7943 76.597 38.3796 77.5 38.9996C76.597 39.6196 75.6683 40.2049 74.7159 40.7537C68.5939 44.2815 61.4921 46.2996 53.919 46.2996C46.3458 46.2996 39.244 44.2815 33.122 40.7537Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.32921 38.9996C11.645 41.7477 17.679 43.2996 24.0811 43.2996C30.4832 43.2996 36.5172 41.7477 41.833 38.9996C36.5172 36.2515 30.4832 34.6996 24.0811 34.6996C17.679 34.6996 11.645 36.2515 6.32921 38.9996ZM3.28414 40.7537C2.33176 40.2049 1.4031 39.6196 0.500061 38.9996C1.4031 38.3796 2.33176 37.7943 3.28414 37.2455C9.40612 33.7177 16.5079 31.6996 24.0811 31.6996C31.6542 31.6996 38.756 33.7177 44.878 37.2455C45.8304 37.7943 46.7591 38.3796 47.6621 38.9996C46.7591 39.6196 45.8304 40.2049 44.878 40.7537C38.756 44.2815 31.6542 46.2996 24.0811 46.2996C16.5079 46.2996 9.40612 44.2815 3.28414 40.7537Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M41.0034 41.0023C39.1877 35.3004 36.0184 29.9364 31.4916 25.4096C26.9648 20.8828 21.601 17.7136 15.8992 15.8979C17.7148 21.5998 20.8841 26.9637 25.4109 31.4906C29.9377 36.0173 35.3016 39.1866 41.0034 41.0023ZM44.3969 41.9152C44.6823 42.9767 44.9251 44.0473 45.1253 45.1242C44.0483 44.924 42.9778 44.6812 41.9163 44.3958C35.0931 42.5614 28.6445 38.9668 23.2896 33.6119C17.9347 28.2569 14.34 21.8083 12.5056 14.985C12.2202 13.9235 11.9774 12.8529 11.7773 11.776C12.8542 11.9762 13.9248 12.2189 14.9863 12.5043C21.8095 14.3387 28.258 17.9334 33.6129 23.2883C38.9679 28.6432 42.5625 35.0919 44.3969 41.9152Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M63.0153 65.4947C64.0768 65.7801 65.1474 66.0229 66.2243 66.223C66.0241 65.1461 65.7813 64.0755 65.496 63.014C63.6615 56.1907 60.0669 49.7421 54.7119 44.3872C49.3569 39.0321 42.9081 35.4374 36.0846 33.603C35.0231 33.3177 33.9526 33.0749 32.8756 32.8747C33.0758 33.9517 33.3186 35.0223 33.604 36.0838C35.4384 42.907 39.033 49.3557 44.388 54.7106C49.743 60.0656 56.1918 63.6604 63.0153 65.4947ZM62.1024 62.1011C60.2867 56.3993 57.1174 51.0354 52.5906 46.5085C48.0637 41.9816 42.6996 38.8123 36.9975 36.9966C38.8132 42.6985 41.9825 48.0624 46.5093 52.5893C51.0363 57.1162 56.4003 60.2855 62.1024 62.1011Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.9972 41.0026C42.6992 39.1869 48.0633 36.0176 52.5902 31.4907C57.1171 26.9638 60.2864 21.5998 62.1021 15.8979C56.4 17.7135 51.036 20.8828 46.509 25.4098C41.9821 29.9367 38.8129 35.3006 36.9972 41.0026ZM36.0843 44.3961C35.0228 44.6815 33.9523 44.9243 32.8753 45.1244C33.0755 44.0475 33.3183 42.9769 33.6037 41.9154C35.438 35.0921 39.0327 28.6434 44.3877 23.2884C49.7427 17.9334 56.1915 14.3387 63.0149 12.5043C64.0764 12.219 65.147 11.9762 66.2239 11.776C66.0237 12.853 65.781 13.9235 65.4956 14.985C63.6612 21.8084 60.0665 28.257 54.7115 33.612C49.3565 38.967 42.9077 42.5617 36.0843 44.3961Z" fill="#7978AA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8982 62.1013C21.6002 60.2857 26.9643 57.1164 31.4912 52.5894C36.0181 48.0626 39.1874 42.6986 41.0031 36.9966C35.301 38.8123 29.937 41.9816 25.41 46.5085C20.8831 51.0354 17.7138 56.3994 15.8982 62.1013ZM14.9853 65.4949C13.9238 65.7802 12.8533 66.023 11.7763 66.2232C11.9765 65.1462 12.2193 64.0757 12.5047 63.0142C14.339 56.1909 17.9337 49.7422 23.2887 44.3872C28.6437 39.0322 35.0925 35.4375 41.9159 33.6031C42.9774 33.3177 44.048 33.075 45.1249 32.8748C44.9247 33.9517 44.682 35.0223 44.3966 36.0838C42.5622 42.9071 38.9675 49.3558 33.6125 54.7108C28.2575 60.0658 21.8087 63.6605 14.9853 65.4949Z" fill="#7978AA"/>
</g>
<defs>
<clipPath id="clip0_434_737">
<rect width="78" height="77" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>;

export const stationIcons = {
    dnb,
    dnbActive,
    dnbInactive,
    deep,
    deepActive,
    deepInactive,
    house,
    houseActive,
    houseInactive,
    personal,
    personalActive,
    personalInactive
};