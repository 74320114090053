import { action, makeObservable, observable } from "mobx";
import axios, { AxiosError, AxiosResponse } from "axios";
import { StationModel, CustomStationModel, StationType } from "entities/Station";
import { IStationModelConfig, ListType, StationUnionType, SwitchDirection } from "entities/Station/types";
import { ISongFavoriteResponseConfig, SongFavoriteModel } from "entities/Song";
import {
    BACKEND_URL,
    MY_STATION,
    STATION_LIST_PATH,
    STATION_PATH,
    STATION_TOP_PATH,
    TRACKS_PATH
} from "shared/config/backendPaths";
import { EventEmitter } from "shared/lib/EventEmitter";
import { getCurrentToken } from "shared/lib/getCurrentToken";
import i18n from "shared/lib/i18n";

type EntityListEvents = {
  "initialFetch": () => void;
  "stationChanged": (wssCode: string) => void;
  "fetchStationList": () => Promise<AxiosResponse<IStationModelConfig[], any> | undefined>;
};

export class StationSwitchStore extends EventEmitter<EntityListEvents> {
  private static shared: StationSwitchStore | null;

  public stationList: [] | (StationModel | CustomStationModel)[] = [];

  public currentStation: StationModel | CustomStationModel | null = null;

  public topTracks: SongFavoriteModel[] = [];

  public myTracks: SongFavoriteModel[] = [];

  public listType: ListType = "personal";

  constructor() {
    super();

    makeObservable(this, {
      stationList: observable,
      currentStation: observable,
      topTracks: observable,
      myTracks: observable,
      listType: observable,
      setStations: action,
      setCurrent: action,
      setTopTracks: action,
      setMyTracks: action,
    });

    this.on("fetchStationList", async () => await this.getStationList());
  }

  async setupStations() {
    await this.getStationList();
    this.setDefault();
    await this.getTopTracks();
    await this.getMyTracks();
  }

  setDefault() {
    if (this.currentStation) return;
    const currentStationStr = localStorage.getItem(StationType.CURRENT);

    if (!currentStationStr) {
      const deepStation = this.stationList.find(el => el.name === 'deep');
      if (deepStation) {
        this.setCurrent(deepStation);
      }
    } else {
      const currentStation: StationModel | CustomStationModel = JSON.parse(currentStationStr);
      if (currentStation) this.setCurrent(currentStation);
    }
  }

  async getStationList() {
    const token = getCurrentToken();

    try {
      const result: AxiosResponse<IStationModelConfig[]> = await axios.get(BACKEND_URL + STATION_LIST_PATH, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (result.status === 200) {
        const items: (StationModel | CustomStationModel)[] = result
          .data.map((item: IStationModelConfig) => item.wss_code
            ? new StationModel(item)
            : new CustomStationModel(item));

        this.setStations(items);
        this.emit("initialFetch");
      }

      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.warn(`${i18n.t("stationsRequestError")}`, error);
      }
    }
  }

  async getTopTracks() {
    if (!this.currentStation) return;
    const currentStationName = this.currentStation.name;
    try {
      const result = await axios
        .get(`${BACKEND_URL}${STATION_PATH}/${currentStationName}${STATION_TOP_PATH}`);

      if (result && result.status === 200) {
        this.setTopTracks(result.data.map((item: ISongFavoriteResponseConfig) => new SongFavoriteModel(item)));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.warn(`${i18n.t("topTracksRequestError")}`, error);
      }
    }
  }

  async getMyTracks() {
    const token = getCurrentToken();
    if (!token) return;
    try {
      const result = await axios
        .get(BACKEND_URL + TRACKS_PATH + MY_STATION, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

      if (result && result.status === 200) {
        const newData = result.data.map((item: ISongFavoriteResponseConfig) => new SongFavoriteModel(item));

        if (this.myTracks) {
          const newDataToCheck = JSON.stringify(newData);
          const prevDataToCheck = JSON.stringify(this.myTracks);
          if (newDataToCheck !== prevDataToCheck) {
            this.setMyTracks(newData);
          }
        } else {
          this.setMyTracks(newData);
        }
      }

      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.warn(`${i18n.t("myTracksRequestError")}`, error);
      }
    }
  }

  setTopTracks(items: SongFavoriteModel[]) {
    this.topTracks = items;
  }

  setMyTracks(items: SongFavoriteModel[]) {
    this.myTracks = items;
  }

  setStations(items: StationModel[]) {
    this.stationList = items;
  }

  setCurrent(item: StationUnionType) {
    if (this.currentStation?.id === item.id) return;
    this.currentStation = item;

    /* Сохранение выбранной станции в lc */
    const newCurrStationLock = JSON.stringify(item);
    const prevCurrStationLock = localStorage.getItem(StationType.CURRENT);

    if (newCurrStationLock !== prevCurrStationLock) {
      if (prevCurrStationLock) {
        localStorage.setItem(StationType.PREVIOUS, prevCurrStationLock);
      }

      localStorage.setItem(StationType.CURRENT, newCurrStationLock);

      if (item.wssCode && prevCurrStationLock) {
        this.emit("stationChanged", item.wssCode);
      }
    }
  }

  switchStation(direction: SwitchDirection) {
    const availableStations = this.stationList.filter((station) => station.isAvailable);
    const currentIndex = availableStations.findIndex(station => station.id === this.currentStation?.id);
    const stationsLength = availableStations.length;

    const previous = availableStations[(currentIndex + stationsLength - 1) % stationsLength];
    const next = availableStations[(currentIndex + 1) % stationsLength];

    this.setCurrent(direction === "next"? next : previous);
  }

  setListType(type: ListType) {
    this.listType = type;
  }

  static getShared(): StationSwitchStore {
    if (this.shared) {
      return this.shared;
    }

    this.shared = new StationSwitchStore();
    return this.shared;
  }
}