import "./Plan.css";

import { Fragment, useMemo } from "react";
import { ITariffModel } from "entities/Tariff";
import { RmdIcon } from "shared/ui/RmdIcon";
import { Button } from "shared/ui/Button";

import { plansIcons } from "./config";
import { TippyWrapper } from "shared/lib/tippy";

type Props = {
  title: string;
  config: ITariffModel;
};

export const Plan = ({ title, config }: Props) => {
  const { price, name, info, action } = config;
  const minDataInfo = useMemo(() => {
    return info.filter((item, idx) => idx <= 1 && item)
  }, [info]);

  return (
    <li className="plan">
      <div className="plan__content">
        <div className="plan__header">
          <div className="plan__top">
            <RmdIcon customCollection={plansIcons} type={title} />
            <h3 className="plan__title">{name}</h3>
            <div className="plan__separator"/>
            <p className="plan__price">{price}$</p>
          </div>
          <TippyWrapper
            className="plan__hint"
            content={
              <Fragment>
                {info.map((item, idx) => <span key={idx}>• {item}</span>)}
              </Fragment>
            }
          >
            <span className="plan__more-about">Подробнее</span>
          </TippyWrapper>
        </div>
      </div>
      {action &&
        <Button
          type="primary"
          action={action}
          text="Оформить"
        />
      }
    </li>
  );
};