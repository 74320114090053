import { ChangeEvent, FormEvent, Fragment, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Form, FormTextLink } from '../../shared/ui/Form';
import { Button } from '../../shared/ui/Button';
import { StringInput } from '../../shared/ui/StringInput';
import { AuthStore } from './model/AuthStore';
import { IUserRequestData } from './types';
import './AuthForm.css';
import {useTranslation} from "react-i18next";

type Props = {
    store: AuthStore;
};

export const AuthForm = observer(({ store }: Props) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<IUserRequestData>({
        email: "",
        password: ""
    });

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => setUserData((prev) => ({
        ...prev,
        [evt.target.name]: evt.target.value
    }));

    const handleSubmitForm = async (evt: FormEvent) => {
        evt.preventDefault();
        await store.login(userData);
        store.closeForm();
        setUserData({
            email: "",
            password: "",
        });
    };

    return (
        <div className="auth-form">
            <Form
                formTitle={t("authorization")}
                onFormSubmit={handleSubmitForm}
            >
                <Fragment>
                    <StringInput
                        type="email"
                        name="email"
                        placeholder="email"
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={32}
                    />
                    <StringInput
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <FormTextLink
                        message={`${t("forgotPassword")}?`}
                        actionMessage={t("restorePassword")}
                        action={() => store.toggleNeedCover()}
                    />
                    <Button
                        type="primary"
                        actionType="submit"
                        size="large"
                        text={t("login")}
                    />
                </Fragment>
            </Form>
        </div>
    );
});