import { PlayerControlStore } from "features/PlayerControls";
import { EqualizerVisualStore } from "features/Equalizer";

export class PlayerStore {
  private static shared: PlayerStore;

  playerControlStore: PlayerControlStore;

  visualizationStore = new EqualizerVisualStore();

  constructor() {
    this.playerControlStore = PlayerControlStore.getShared(this.visualizationStore);
  }

  static getShared(): PlayerStore {
    if (this.shared) {
      return this.shared;
    }
    this.shared = new PlayerStore();
    return this.shared;
  }
}