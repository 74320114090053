import uuid from "react-uuid";
import { NotificationStore } from "features/Notification";
import { AuthStore } from "features/Auth";
import { RegisterStore } from "features/Register";
import { IAuthResponseData, ILogoutResponse } from "features/Auth/types";
import { IRegisterResponseData } from "features/Register/types";

import i18n from "../../shared/lib/i18n";

export class AuthProcess {
  private static shared: AuthProcess | null;

  registerStore: RegisterStore = new RegisterStore();

  authStore: AuthStore = AuthStore.getShared();

  notificationStore: NotificationStore = new NotificationStore();

    constructor() {
      this.registerStore.on("registerSuccess", (data: IRegisterResponseData) => {
        if (data) {
          const status = data.code === 200 ? "success" : "default";

          this.notificationStore.createNotification({
            status,
            title: i18n.t("registrationSuccess"),
            body: data.message,
            id: uuid()
          });
        }
      });

      this.registerStore.on("verificationCompleted", (data: string) => {
        this.notificationStore.createNotification({
          status: "success",
          title: data,
          id: uuid()
        });
      });

      this.registerStore.on("registerError", () => {
        this.notificationStore.createNotification({
          status: "error",
          title: i18n.t("emailAlreadyExists"),
          id: uuid()
        })
      });

      this.authStore.on("authUserDataReceived", (data: IAuthResponseData) => {
        if (data) {
          if (data.user.email_verified_at === null) {
            this.notificationStore.createNotification({
              status: "error",
              title: i18n.t("emailUnverified"),
              body: `${i18n.t("letterWasSent")}: ${data.user.email}`,
              id: uuid()
            });
          } else {
            this.notificationStore.createNotification({
              status: "success",
              title: i18n.t("verifiedSuccessfully"),
              id: uuid()
            });
          }
        }
      });

      this.authStore.on("logoutSuccess", (data: ILogoutResponse) => {
        if (data && data.status === 200) {
          this.notificationStore.createNotification({
            status: "success",
            title: i18n.t("logoutSuccess"),
            id: uuid()
          });
        }
      });

      this.authStore.on("invalidCredential", () => {
        this.notificationStore.createNotification({
          status: "error",
          title: `${i18n.t("wrongEmailOrPass")}!`,
          id: uuid()
        });
      });

    this.authStore.on("recoverRequested", (message: string) => {
      this.notificationStore.createNotification({
        status: "success",
        title: `${i18n.t("recoverRequested")}!`,
        body: message,
        id: uuid()
      });
    });

    this.authStore.on("passwordConfirmed", (message: string) => {
      this.notificationStore.createNotification({
        status: "success",
        title: message,
        id: uuid()
      });
    });
  }

  static getShared(): AuthProcess {
    if (this.shared) {
      return this.shared;
    }
    this.shared = new AuthProcess();
    return this.shared;
  }
}