import { action, makeObservable, observable } from "mobx";
import { NotificationModel } from "../../../entities/Notification";
import { INotificationConfig } from "../../../entities/Notification/types/INotificationConfig";

export class NotificationStore {
    isShown: boolean = false;

    stack: NotificationModel[] = [];

    constructor() {
        makeObservable(this, {
            isShown: observable,
            stack: observable,
            pushToStack: action,
            removeFromStack: action
        })
    }

    pushToStack(model: NotificationModel) {
        this.stack.push(model);
    }

    removeFromStack(model: NotificationModel) {
        if (this.stack.length === 1) {
            this.stack = [];
        } else if (this.stack.length > 1) {
            this.stack = this.stack.filter((item) => model.id !== item.id);
        }
    }

    createNotification({ status, title, body, id }: INotificationConfig) {
        const notification = NotificationModel.init({
            status, title, body, id
        });
        this.pushToStack(notification);
        this.removeByTimer(notification);
    }

    removeByTimer(notification: NotificationModel) {
        setTimeout(() => {
            this.removeFromStack(notification);
        }, 6000);
    }
}