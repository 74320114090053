import { IUserConfig } from "./types/IUserConfig";

export class UserModel {
    id: number;

    firstName: string;

    lastName: string;

    email: string;

    typeUser: string;

    constructor({ id, firstName, lastName, email, typeUser }: IUserConfig) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.typeUser = typeUser;
    }

    static init({ id, firstName, lastName, email, typeUser }: IUserConfig) {
        return new UserModel({ id, firstName, lastName, email, typeUser });
    }
}