import './PageNotFound.css';

import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import { pagePaths } from "shared/config";
import { RmdLogo } from "shared/ui/RmdLogo";

import { RmdPage } from '../RmdPage';

export const PageNotFound = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  /**
   * @TODO: Доделать вывод запроса на страницу
   */
  // const query: { path?: string } = searchQuery(window.location.search);
  return (
    <RmdPage
      headerLeft={""}
      headerMiddle={<RmdLogo onClick={() => navigate(pagePaths.HOME_ROUTE)}/>}
      headerRight={""}
      body={
        <div className="page-not-found">
          <p className="page-not-found__title">{t("pageNotFound")}...</p>
          {/*<p className="page-not-found__reason">адрес:*/}
          {/*    <span className="page-not-found__query">{' ' + query.path}</span>*/}
          {/*</p>*/}
        </div>
        }
    />
  );
});