import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { useScreenMode } from "app";
import { AuthBtn, AuthLogOut as LogoutBtn } from "features/Auth";
import { RegisterBtn } from "features/Register";
import { rmdIcons } from "shared/icons";
import { CircleButton } from "shared/ui/CircleButton";
import { Preloader } from "shared/ui/Preloader";
import { LocalizationBtn } from "features/Localization/LocalizationBtn";

import { IAuthConfig, INotAuthConfig } from "../../types";

interface Props {
  isLoading: boolean;
  isAuth: boolean;
  authConfig: IAuthConfig;
  notAuthConfig: INotAuthConfig;
}

export const HeaderContent = observer(({
  isLoading,
  isAuth,
  authConfig,
  notAuthConfig,
 }: Props) => {
  const isMobile = useScreenMode();
  const { t } = useTranslation();

  if (isLoading) {
    return <Preloader/>
  }

  const Component = {
    "auth": <Fragment>
      {!isMobile && <CircleButton
        action={authConfig.action}
        children={rmdIcons.account}
        hint={
          <p className="account__email">{authConfig.userEmail}</p>
        }
      />}
      <LogoutBtn action={authConfig.onLogout}/>
    </Fragment>,
    "not-auth": <Fragment>
      <LocalizationBtn />
      <RegisterBtn
        action={notAuthConfig.register.action}
        isMobile={isMobile}
      />
      <AuthBtn
        action={notAuthConfig.auth.action}
        isMobile={isMobile}
      />
    </Fragment>,
  }

  return Component[isAuth ? "auth" : "not-auth"];
});
