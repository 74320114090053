import "./Equalizer.css";

import { Fragment } from "react";
import { observer } from "mobx-react-lite";

type Props = {
  svgRef: any;
  isVisible: boolean;
};

export const Equalizer = observer(({ svgRef, isVisible }: Props) => (
  isVisible
    ? <Fragment>
        <div className="player-equalizer__fg" />
        <div className="player-equalizer__bg" ref={svgRef} />
      </Fragment>
    : <Fragment />
));
