import { observer } from "mobx-react-lite";
import { Fragment } from "react";

import { ListType } from "entities/Station/types";
import { FavouriteModel } from "entities/Favourite";

import { SongList } from "./SongList";
import { SongFavoriteModel } from "../../model/SongFavoriteModel";

type Props = {
  isVisible: boolean;
  items: SongFavoriteModel[];
  likedSongs: FavouriteModel[];
  handleNonAuthAction(): void;
  isAuth: boolean;
  title: string;
  headerChildren?: JSX.Element;
  listType: ListType;
};

export const Tracks = observer(({
  isVisible,
  items,
  likedSongs,
  handleNonAuthAction,
  isAuth,
  title,
  headerChildren,
  listType,
}: Props) => (
  isVisible ? (
    <SongList
      items={items}
      likedSongs={likedSongs}
      handleNonAuthAction={handleNonAuthAction}
      isAuth={isAuth}
      title={title}
      headerChildren={headerChildren}
      listType={listType}
    />
  ) : <Fragment />
));