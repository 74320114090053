import { Button } from "../../shared/ui/Button";
import { useTranslation } from "react-i18next";

type Props = {
    action: () => void;
    isMobile: boolean;
};

export const RegisterBtn = ({ action, isMobile }: Props) => {
    const { t } = useTranslation();

    return (
        <Button
            type={isMobile ? "mobile" : "primary"}
            action={action}
            size="medium"
            text={t("registration")}
        />
    );
};