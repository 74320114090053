import './RegisterForm.css';

import { ChangeEvent, FormEvent, Fragment, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Form } from 'shared/ui/Form';
import { Button } from 'shared/ui/Button';
import { StringInput } from 'shared/ui/StringInput';

import { RegisterStore } from './store/RegisterStore';
import { IUserData } from './types';
import { useTranslation } from "react-i18next";

type Props = {
    store: RegisterStore;
};

export const RegisterForm = observer(({ store }: Props) => {
    const { t } = useTranslation();

    const [userData, setUserData] = useState<IUserData>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirmation: ""
    });

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => setUserData((prev) => ({
        ...prev,
        [evt.target.name]: evt.target.value
    }));

    const handleSubmitForm = async (evt: FormEvent) => {
        evt.preventDefault();
        await store.register(userData);
        store.closeForm();
        setUserData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirmation: ""
        });
    };

    return (
        <div className="register-form">
            <Form
                formTitle={t("registration")}
                onFormSubmit={handleSubmitForm}
            >
                <Fragment>
                    <StringInput
                        type="text"
                        placeholder={t("name")}
                        name="firstName"
                        onChange={onInputChange}
                        minLength={2}
                        maxLength={18}
                    />
                    <StringInput
                        type="text"
                        name="lastName"
                        placeholder={t("surname")}
                        onChange={onInputChange}
                        minLength={2}
                        maxLength={18}
                    />
                    <StringInput
                        type="email"
                        name="email"
                        placeholder="email"
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={32}
                    />
                    <StringInput
                        type="password"
                        name="password"
                        placeholder={t("password")}
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <StringInput
                        type="password"
                        name="passwordConfirmation"
                        placeholder={t("repeatPassword")}
                        onChange={onInputChange}
                        minLength={8}
                        maxLength={20}
                    />
                    <Button
                        type="primary"
                        actionType="submit"
                        text={t("registration")}
                        size="large"
                    />
                </Fragment>
            </Form>
        </div>
    );
});